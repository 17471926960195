import React, { useEffect, useState } from "react";
import "./returnslippdf.css";
import { useParams, useNavigate } from "react-router-dom";
import { callApi } from "../../Utils/Api";
import rs1 from "../../assets/rs1.svg";
import rs from "../../assets/rs.svg";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import {
  faChevronRight,
  faPlus,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col } from "rsuite";

// Define interfaces for the data structure
interface Item {
  id: string;
  orginal_product: string;
  orginal_qty: string;
  mis_matched_product: string;
  mis_matched_qty: string;
}

interface PurchaseOrder {
  id: string;
  labour_status: string;
  shipping_address: string | null;
  po_number: string;
  po_date: string;
  vendor_name: string;
  po_value: string;
  vendor_id: string;
  pe_value: string;
  over_all_credit: string;
  balance_credit: number;
  site_id: string;
  remarks: string;
  rt_no: string;
  rt_date: string;
  items: Item[];
}

const Returnslippdf = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<PurchaseOrder | null>(null);
  const logo1 = require("../../assets/sidebar/image1.png");
  const { rtId } = useParams<{ rtId: string }>();

  useEffect(() => {
    getQuotationDetails();
  }, []);

  const getQuotationDetails = () => {
    const req = {
      rt_id: rtId,
    };
    callApi("post", "return_select.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["response_Code"] === 200) {
          setData(response.responseJson[0]);
        }
      });
  };

  if (!data) {
    return <div>Loading...</div>;
  }
  const sitid = localStorage.getItem("siteId");
  const handlegoBack = () => {
    navigate(`/layout/sitedetails/${sitid}`);
  };
  const shipadd = localStorage.getItem("shipadd") || "";

  const handleDownloadPDF = () => {
    const input = document.getElementById("pdf-content");
    if (input) {
      html2canvas(input).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const imgWidth = 210; // A4 width in mm
        const pageHeight = 295; // A4 height in mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        let position = 0;

        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save(`purchase_order.pdf`);
      });
    }
  };

  return (
    <>
      <div className="container">
        <div style={{ padding: "20px" }}>
          <div className="text-center col-flex11">
            <button className="btn excelbtn mr-2" onClick={handlegoBack}>
              Go Back
            </button>
            <Row className="align-items-center" style={{ display: "contents" }}>
              <Col className="flex">
                {/* <h5 onClick={() => purchaseentryroute()}>
                  Purchase Entry{" "}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="righticon"
                  />{" "}
                </h5> */}
              </Col>
              <Col className="flex">
                {/* <h5 onClick={() => handlereturnslip()}>
                  Return Slip{" "}
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="righticon"
                  />
                </h5> */}
              </Col>
            </Row>
            <button className="btn excelbtn ml-2" onClick={handleDownloadPDF}>
              Download PDF
            </button>
          </div>
        </div>
        <div className="card quotationcard" id="pdf-content">
          <div className="header1">
            <div>
              <img src={logo1} alt="D&D Architects Logo" width="100" />
            </div>
            <div className="title-qt">
              <h3>D&D ARCHITECTS</h3>
            </div>

            <div className="quotation-text">
              <h4>RETURN SLIP</h4>
            </div>
          </div>
          <div className="section-title contact-info">
            <p>
              <strong>Contact: +91 99433 44464</strong>
            </p>
            <p>
              <strong>Website: http://www.ddarchitects.co</strong>
            </p>
          </div>
          <div className="section">
            <div className="quote-info">
              <div className="projectdiv">
                <h6>VENDOR</h6>
              </div>
              <div className="projectdiv">
                <h6>SHIP TO</h6>
              </div>
              <div></div>
            </div>
          </div>

          <div className="section">
            <div className="quote-info">
              <div
                className=""
                style={{
                  backgroundColor: "#00a7f6",
                  color: "white",
                }}
              >
                <p>
                  <strong>{data.vendor_name}</strong>
                </p>
                <p>
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: shipadd,
                    }}
                  />
                </p>
              </div>
              <div
                className=""
                style={{
                  backgroundColor: "#00a7f6",
                  color: "white",
                }}
              >
                {" "}
                <p>
                  <strong>D&D Architects</strong>
                </p>
                <p>
                  <strong>F7,Shanmugam Complex,1st Floor</strong>
                </p>
                <p>
                  <strong>Mtp Road,RS Purum</strong>
                </p>
                <p>
                  <strong>Coimbatore-641002</strong>
                </p>
              </div>
              <div
                style={{
                  backgroundColor: "rgb(89 89 89)",
                  color: "white",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                <p>
                  <strong>
                    DATE: <span>{data.rt_date}</span>
                  </strong>
                </p>
                <p>
                  <strong>RS : {data.rt_no}</strong>
                </p>
              </div>
            </div>
          </div>

          <div className="section roomsection">
            <table className="table1">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Ordered Product</th>
                  <th>Qty</th>
                  <th>Miss Matched Product</th>
                  <th>Qty</th>
                </tr>
              </thead>
              <tbody>
                {data.items.map((item, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{item.orginal_product}</td>
                    <td>{item.orginal_qty}</td>
                    <td>{item.mis_matched_product}</td>
                    <td>{item.mis_matched_qty}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data.remarks !== "" ? (
              <div className="materials-section">
                <h5 className="underline">Remarks:</h5>
                <p dangerouslySetInnerHTML={{ __html: data.remarks }} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Returnslippdf;
