import React, { FormEvent, useState, ChangeEvent, useEffect } from 'react';
import Papa from "papaparse";
// import { Table, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../usermanagement/usermanagement.css";
import { showAlert, showError, showSuccess } from '../../../Utils/sweetAlert';
import { callApi } from '../../../Utils/Api';
import { isEmpty } from 'validator';
import Column from 'rsuite/esm/Table/TableColumn';
import { Table, Pagination, Modal, Button } from "rsuite";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faEdit,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "../../page/site/Sitelist.css";
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

// Define FormErrors interface
// interface FormErrors {
//     name: string;
//     mobile?: number;
//     email?: string;
//     password?: string;
//     confirm_password?: string;
// }

const Listofvendor = () => {
    const [formData, setFormData] = useState({
        id: '',
        vendorname: '',
        contactnumber: '',
        contactname: '',
        gstnumber: '',
        billingaddress: ''
    });
    const navigate = useNavigate()
    const [error, setError] = useState(false);
    const [contactnumbererror, setcontactnumbererror] = useState(false);
    const [contactnameerror, setcontactnameerror] = useState(false);
    const [vendornameerror, setvendornameerror] = useState(false);
    const [gstnumbererror, setgstnumbererror] = useState(false);
    const [confirm_passworderror, setconfirm_passworderror] = useState(false)
    const [modalTitle, setModalTitle] = useState("");
    const [state, setState] = useState({
        UserListSet: [],
    });
    const [isToggled, setIsToggled] = useState(false);
    const [billingaddresserror, setbillingaddresserror] = useState(false)
    // const [searchQuery, setSearchQuery] = useState('');


    const [tableData, setTableData] = useState<any[]>([]);
    const [dataPeriod, setDataPeriod] = useState<"yearly" | "monthly">("monthly");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    // const [siteDetails, setSiteDetails] = useState({
    //     name: "",
    //     customer_name: "",
    //     contact: "",
    //     billing_address: "",
    //     shipping_address: "",
    // });
    const [editingSiteDetails, setEditingSiteDetails] = useState<any>(null); // State for editing

    const [searchQuery, setSearchQuery] = useState("");

    const { Column, HeaderCell, Cell } = Table;
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);

    const totalPages = Math.ceil(state.UserListSet.length / rowsPerPage);
    const paginatedData = state.UserListSet.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const getData = () => {
        let filteredData = state.UserListSet;

        // Apply search filter if searchQuery exists
        if (searchQuery) {
            filteredData = state.UserListSet.filter((row: any) =>
                Object.values(row).some((value) =>
                    typeof value === "string"
                        ? value.toLowerCase().includes(searchQuery.toLowerCase())
                        : false
                )
            );
        }

        // Apply sorting if sortColumn and sortType are provided
        if (sortColumn && sortType) {
            filteredData = filteredData.slice().sort((a: any, b: any) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === "string" && typeof y === "string") {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }

                if (sortType === "asc") {
                    return x > y ? 1 : -1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }

        console.log("Filtered Data: ", filteredData);

        // Apply pagination
        const start = (page - 1) * limit;
        const end = start + limit;

        return filteredData.slice(start, end);
    };


    const handleSortColumn = (sortColumn: any, sortType: any) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    // useEffect(() => {
    //     getSitelist();
    // }, []);

    const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
        setPage(1);
        setLimit(dataKey);
    };
    const handleCellClick = (rowData: any) => {
        console.log("rowData", rowData.id);

        console.log("navigate", rowData.id); // Print the name
        navigate('/layout/vendorsitelist', { state: { rowData } }); // Navigate to the vendor page within layout
    };
   
    const handlePageClickPrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageClickNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value =
            event.target.value === "All"
                ? state.UserListSet.length
                : Number(event.target.value);
        setRowsPerPage(value);
        setCurrentPage(1); // Reset to first page when rows per page changes
    };

    const handleShowModalAdd = () => {
        setShowModalAdd(true);

    };

   
    const handleCloseModal = () => {
        setShowModal(false)
        setFormData({
            id: "",
            vendorname: "",
            contactname: "",
            contactnumber: "",
            gstnumber: "",
            billingaddress: "",


        });
        setError(false)
        setvendornameerror(false)
        setcontactnumbererror(false);
        setcontactnameerror(false);
        setgstnumbererror(false);
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const handletoggle = (e: ChangeEvent<HTMLInputElement>) => {
        setIsToggled(!isToggled);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;

    // Convert GST number to uppercase
    const updatedValue = name === "gstnumber" ? value.toUpperCase() : value;

    // Update state for form fields
    setFormData((prevState) => ({
        ...prevState,
        [name]: updatedValue
    }));

        // Perform validation based on field name
        if (name === "contactnumber") {
            const isValidInput = /^[0-9/]*$/.test(value);
            if (!isValidInput || value.length < 10) {
                setcontactnumbererror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setcontactnumbererror(false);
            }
        } else if (name === "vendorname") {
            const isValidInput = /^[a-zA-Z., ]+$/.test(value);
            if (!isValidInput) {
                setvendornameerror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setvendornameerror(false);
            }
        }
        else if (name === "contactname") {
            const isValidInput = /^[a-zA-Z ]+$/.test(value);
            if (!isValidInput) {
                setcontactnameerror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setcontactnameerror(false);
            }
        } else if (name === "gstnumber") {
            const isValidInput = /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/.test(value);
            if (!isValidInput) {
                setgstnumbererror(true);
                console.error("Invalid input. Please enter a valid GST Number.");
            } else {
                setgstnumbererror(false);
            }
        }
    };
    useEffect(() => {
        getUserDetails();
    }, []);

    const handlesaveuser = (e: FormEvent) => {
        e.preventDefault();

        console.log(
            "formFields_________",
            formData
        );

        if (
            isEmpty(formData.vendorname) ||
            isEmpty(formData.contactname) ||
            isEmpty(formData.contactnumber) ||

            isEmpty(formData.billingaddress)
            // isEmpty(formData.mobile.length < 10)
        ) {

            setError(true);
        } else if (
            vendornameerror ||
            contactnameerror ||
            contactnumbererror ||
            gstnumbererror ||
            billingaddresserror
        ) {
            // if (vendornameerror === true) {
            //     setErrors("Vendor Name Error");
            // } else if (contactnameerror === true) {
            //     setErrors("Contact Name Error");
            // } else if (contactnumbererror === true) {
            //     setErrors("Mobile number Error");
            // } else if (gstnumbererror === true) {
            //     setErrors("Gst Number Error");
            // } else if (billingaddresserror === true) {
            //     setErrors("Billingaddress Error");
            // }

        } else {
            console.log("jgjhjh", modalTitle);

            if (modalTitle === "Add Vendor") {
                let request = {
                    "vendor_name": formData.vendorname,
                    "contact_no": formData.contactnumber,
                    "gst_number": formData.gstnumber,
                    "billing_address": formData.billingaddress,
                    "contact_name": formData.contactname

                };
                callApi("POST", "vendor_create.php", request)
                    .then((res: any) => res.data)
                    .then((response: any) => {
                        if (response.responseCode === 200) {
                            showSuccess("Success!", response.message);

                            setFormData({
                                id: "",
                                vendorname: "",
                                contactname: "",
                                contactnumber: "",
                                gstnumber: "",
                                billingaddress: ""
                            });
                            setShowModal(false);
                            getUserDetails();
                        }
                        else {
                            showError("Failed", response.message);
                        }
                    });
            }
            else {

                editingdetails();
            }
        }
    };
    const getUserDetails = () => {
        callApi("post", "vendor_list.php", "")
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response["response_Code"] === 200) {
                    console.log("coming");
                    console.log("response.responseJson", response.responseJson);

                    setState((prevState) => ({
                        ...prevState,
                        UserListSet: response.responseJson,
                    }))

                }
                console.log("UserListSet", state.UserListSet);

            })
    }

    const addUser = () => {
        setModalTitle("Add Vendor");
        setShowModal(true)
    };

    const userEdit = (data: any) => {
        console.log("this is the data", data)
        setFormData({
            id: data.id,
            vendorname: data.name,
            contactname: data.contact_name,
            contactnumber: data.contact_no,
            gstnumber: data.gst_number,
            billingaddress: data.billing_address,
        });
        console.log("muthu---->111", formData);


        setModalTitle("Edit Vendor");
        setShowModal(true);
    };

    const editingdetails = () => {
        let request = {
            vendor_id: formData.id,
            vendor_name: formData.vendorname,
            contact_name: formData.contactname,
            gst_number: formData.gstnumber,
            contact_no: formData.contactnumber,
            billing_address: formData.billingaddress
        };

        console.log("request", request);
        callApi("post", "vendor_update.php", request)

            .then((res: any) => res.data)
            .then((response: any) => {
                if (response.responseCode === 200) {
                    showSuccess("EDITED!", response.message);
                    getUserDetails();
                    setFormData({
                        id: "",
                        vendorname: "",
                        contactname: "",
                        contactnumber: "",
                        gstnumber: "",
                        billingaddress: ""
                    });
                    setShowModal(false);
                } else {
                    showError("FAILED!", response.message);
                }
            });
    };

    const [showModal, setShowModal] = useState(false); 
    const downloadCSV = () => {
        const data = [
            ["listofvendor"],
            ["S.No", "Vendor Name", "Total Balance Credits"],
            ...state.UserListSet.map((row:any) => [row.s_no, row.name, row.total_balance_credits])
        ];
    
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        // Add rows to the worksheet
        data.forEach((row) => {
            worksheet.addRow(row);
        });
    
        // Merge the title row across all columns and center the title
        worksheet.mergeCells('A1:C1');
        const titleCell = worksheet.getCell('A1');
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        titleCell.font = { bold: true, size: 14 };
    
        // Style for the header row (second row)
        const headerRow = worksheet.getRow(2);
        headerRow.eachCell((cell:any) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.font = { bold: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Yellow background
        });
    
        // Write the workbook to an Excel file
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        workbook.xlsx.writeBuffer().then((buffer:any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Vendor List ${formattedDate}.xlsx`;
            link.click();
        });
    };
    
    
    
    
    

    const handlePageClickprevious = () => {
        setCurrentPage(currentPage - 1);
    };
    const handlePageClicknext = () => {
        setCurrentPage(currentPage + 1);
    };

    const handlepageListChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value === "All" ? state.UserListSet.length : Number(event.target.value);
        setRowsPerPage(value);
        setCurrentPage(0); // Reset to first page when rows per page changes
    };

    const handleCheckbox = async (e: any, id: any) => {
        const isComfirm = await showAlert(
            "Please Confirm",
            "Are you sure you want to change the status?",
            "Yes",
            "No"
        );
        const { checked } = e.target;
        console.log("handleCheckbox_____", checked);
        if (isComfirm) {
            const req = {
                id: id,
                status: checked == false ? "1" : "0"
            }
            callApi("POSt", "vendor_delete.php", req)
                .then((res: any) => res.data)
                .then((response: any) => {
                    if (response.responseCode === 200) {
                        showSuccess("CHANGED!", response.message);
                        getUserDetails();
                    } else {
                        showError("FAILED!", "CHANGE failed");
                    }
                });
        }
    }
    const closeModal = () => {
        setShowModal(false);
        setFormData({
            id: "",
            vendorname: "",
            contactname: "",
            contactnumber: "",
            gstnumber: "",
            billingaddress: ""
        });
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    const filteredData = paginatedData.filter((row: any) =>
        row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        row.contact_no.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <div className="container-lt">
                <div className='row'>
                    <div className="tabletitle" style={{ fontSize: "24px" }}>Vendor List</div>
                    <div className="table-header " >
                        <div className='vendor-add-btn'>
                            <button
                                type="button"
                                className="btn excelbtn exbtn "
                                style={{ marginRight: "10px" }}
                                onClick={addUser}
                            >
                                Add Vendor
                            </button>
                        </div>
                        <div className="table-search searchfieldscontain vendor-excel">
                            <button
                                type="button"
                                className="btn excelbtn btnmobiledash "
                                style={{ marginRight: "10px" }}
                                onClick={downloadCSV}
                            >
                                Excel
                            </button>
                            <div className="search-field1">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="search-input vendorsearch"
                                    value={searchQuery}
                                    onChange={handleSearchInputChange}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                {/* <div className="rs-panel-body"> */}

                    <div className="rs-table rs-table-hover">

                        <div style={{ width: "100%", overflowX: "hidden" }}>
                            <Table
                                height={420}
                                data={getData()}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSortColumn={handleSortColumn}
                                loading={loading}
                                style={{ width: "100%" }} // Adjust min-width as needed
                            >
                                <Column width={50} align="center" fixed>
                                    <HeaderCell>S.No</HeaderCell>
                                    <Cell>

                                    {(rowData) => (
                                            <p onClick={() => handleCellClick(rowData)}>{rowData.s_no}</p>
                                        )}
                                    </Cell>
                                </Column>

                                {/* <Column width={380} sortable>
                                    <HeaderCell style={{ fontSize: "16px",placeContent:"center",textAlign:"center" }}>Vendor Name</HeaderCell>
                                    <Cell dataKey="name"style={{placeContent:"center",textAlign:"center"}}   onClick={(rowData) => handleCellClick(rowData)}/>
                                        
                                </Column> */}

                                <Column width={200} >
                                    <HeaderCell>Vendor Name</HeaderCell>
                                    <Cell >
                                        {(rowData) => (
                                            <p onClick={() => handleCellClick(rowData)}>{rowData.name}</p>
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={200} >
                                    <HeaderCell>Total Balance Credits</HeaderCell>
                                    <Cell>
                                    {(rowData) => (
                                            <p onClick={() => handleCellClick(rowData)}>{rowData.total_balance_credits}</p>
                                        )}
                                    </Cell>
                                </Column>

                                <Column width={100}>
                                    <HeaderCell>Action</HeaderCell>
                                    <Cell >
                                        {(rowData) => (
                                            <div >
                                                <button onClick={() => userEdit(rowData)} className='iconbg'><FontAwesomeIcon icon={faEdit} style={{ height: "20px", width: "20px" }} /></button>
                                            </div>
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={100}>
                                    <HeaderCell>Status</HeaderCell>
                                    <Cell >
                                        {(rowData) => (
                                            <label className="switch">
                                                <input type="checkbox" checked={rowData.vendor_status == "1"} onChange={(e) => handleCheckbox(e, rowData.id)} />
                                                <span className="slider round"></span>
                                            </label>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                        </div>
                        <div>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                className="pagination"
                                size="xs"
                                layout={["total", "pager", "skip"]}
                                total={state.UserListSet.length}
                                limitOptions={[5, 10, 15]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleChangeLimit}
                            />
                        </div>
                    </div>
                </div>


            {/* </div> */}

            {/* Modal for adding new user */}
            <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className='modaltittle'>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{ padding: "2%", overflow: "hidden" }}>
                        <div className='row'>
                            <div className="col-md-6 form-group">
                                <label className='labeltittle' htmlFor="name">Vendor Name<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${error && isEmpty(formData.vendorname) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="vendorname"
                                    name="vendorname"
                                    value={formData.vendorname}
                                    minLength={2}
                                    maxLength={40}
                                    onChange={handleChange}
                                />
                                {error && isEmpty(formData.vendorname) && <div className="invalid-feedback"></div>}
                                {vendornameerror && <div className="error-message" style={{ color: "red" }}>Enter valid Vendor Name</div>}
                            </div>
                            <div className="col-md-6 form-group">
                                <label className='labeltittle' htmlFor="name">Contact Name<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${error && isEmpty(formData.contactname) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="contactname"
                                    name="contactname"
                                    value={formData.contactname}
                                    onChange={handleChange}
                                    minLength={2}
                                    maxLength={40}
                                />
                                {error && isEmpty(formData.contactname) && <div className="invalid-feedback"></div>}
                                {contactnameerror && <div className="error-message" style={{ color: "red" }}>Enter valid Contact Name</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-6 form-group">
                                <label className='labeltittle' htmlFor="mobile">Contact Number<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${error && isEmpty(formData.contactnumber) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="contactnumber"
                                    name="contactnumber"
                                    value={formData.contactnumber}
                                    onChange={handleChange}
                                    minLength={10}
                                    maxLength={10}
                                />
                                {error && isEmpty(formData.contactnumber) && <div className="invalid-feedback"></div>}
                                {contactnumbererror && <div className="error-message" style={{ color: "red" }}>Enter valid Contact Number</div>}
                            </div>
                            <div className="col-md-6 form-group">
                                <label className='labeltittle' htmlFor="text">GST Number</label>
                                <input
                                    type="text"
                                    className={`form-control`}
                                    id="gstnumber"
                                    name="gstnumber"
                                    value={formData.gstnumber}
                                    onChange={handleChange}
                                    maxLength={15}
                                />
                                {<div className="invalid-feedback"></div>}
                                {gstnumbererror && <div className="error-message" style={{ color: "red" }}>Enter valid GST number Ex:22AAAAA0000A1Z5</div>}
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-md-12 form-group">
                            <label className='labeltittle' htmlFor="text">Billing Address<span className='mandatoryfield'>*</span></label>
                            <textarea
                                className={`form-control ${error && isEmpty(formData.billingaddress) ? 'is-invalid' : ''} mt-1 mb-2`}
                                id="billingaddress"
                                name="billingaddress"
                                value={formData.billingaddress}
                                onChange={handleChange}
                                minLength={5}
                                maxLength={200}
                            />
                            {error && isEmpty(formData.billingaddress) && <div className="invalid-feedback"></div>}
                            {billingaddresserror && <div className="error-message" style={{ color: "red" }}>Enter valid address</div>}
                        </div>
                        </div>
                        <Button className='newuser mt-3' onClick={(e) => handlesaveuser(e)}>{modalTitle}</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Listofvendor;
