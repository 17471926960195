import React, {
  useEffect,
  useState,
  useRef,
  FormEvent,
  ChangeEvent,
} from "react";
import {
  NavLink,
  Link,
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { Card } from "react-bootstrap";
import { Col, Row, Table, Tabs, Modal, Button, Pagination, Grid } from "rsuite";
import "./SiteDetails.css";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegSquare, FaImage, FaRegCircle } from "react-icons/fa";
import DetailIcon from "@rsuite/icons/Detail";
import PageIcon from "@rsuite/icons/Page";
import TextImageIcon from "@rsuite/icons/TextImage";
import StorageIcon from "@rsuite/icons/Storage";
import AdminIcon from "@rsuite/icons/Admin";
import { callApi } from "../../../Utils/Api";
import { showAlert, showError, showSuccess } from "../../../Utils/sweetAlert";
import {
  faEdit,
  faTrashCan,
  faCheckSquare,
  faUpload,
  faEye,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Papa from "papaparse";
import balance from "../../../assets/dashboard/balance.png";
import vendor from "../../../assets/dashboard/vendor.png";
import labour from "../../../assets/dashboard/labour.png";
import transport from "../../../assets/dashboard/Payment Transport.svg";
import received from "../../../assets/dashboard/dashboard/Received.svg";
import pevendor from "../../../assets/dashboard/dashboard/PE vendor.svg";
import petransport from "../../../assets/dashboard/dashboard/PE transport.svg";
import pelabour from "../../../assets/dashboard/dashboard/PE labour.svg";
import done from "../../../assets/site-dashboard/done.png";
import ExcelJS from "exceljs";

interface Vendor {
  id: string;
  site_name: string;
  vendor_name: string;
  pe_value: number | null;
  overall_credit: string;
  balance_credits: number;
}

interface products {
  s_no: number;
  product_name: string;
  qty: string;
  vendor_name: string | null;
  price: string;
  total_amt: number;
}

interface SiteData {
  id: string;
  name: string;
  customer_name: string;
  contact: string;
  billing_address: string;
  shipping_address: string;
  invoice_value: string;
  invoice_balance: string;
  completed_site_status: string;
  status: string;
}
interface Vendor1 {
  s_no: number;
  id: string;
  name: string;
  contact_no: string;
  contact_name: string;
  gst_number: string;
  billing_address: string;
  vendor_status: string;
  total_balance_credits: any;
}
interface Item {
  item_id: string;
  product_id: string;
  product: string;
  po_qty: string;
  current_qty: string;
  po_unit_price: string;
  po_total: string;
  po_id: string;
  pe_qty: string;
  pe_unit_price: string;
  pe_total: string;
}
interface RowData {
  id: number;
  name: string;
  amount: string;
  vendor_id: string;
}

interface PoData {
  id: string;
  pe_status: string;
  po_number: string;
  po_date: string;
  vendor_name: string | null;
  po_value: string;
  vendor_id: string;
  pe_value: string | null;
  over_all_credit: string | null;
  balance_credit: number;
  site_id: string;
  comments: string;
  items: Item[];
}

interface PeData {
  po_id: string;
  pe_id: string;
  pe_number: string;
  po_number: string;
  pe_value: string;
  pe_date: string;
  vendor_id: string;
  vendor_name: string | null;
  site_id: string;
  comments: string;
  items: Item[];
}
interface invoice {
  id: string;
  site_name: string;
  customer_name: string;
  address: string;
  invoice_id: string | null;
  invoice_amt: string;
  payment_received: string | null;
  invoice_bal: string | null;
  inv_no: string | null;
}
interface quo {
  id: string;
  site_name: string;
  customer_name: string;
  address: string;
  quo_no: string | null;
  quo_amt: string;
  status: string | null;
}

interface RtData {
  id: string;
  po_no: string | null;
  rt_no: string;
  po_id: string;
  vendor_id: string;
  site_id: string;
  bill: string;
  remarks: string;
  items: RtItem[];
  date: string;
  bill_status: string;
}

interface RtItem {
  id: string;
  orginal_product: string;
  orginal_qty: string;
  mis_matched_product: string;
  mis_matched_qty: string;
}
interface Sitecard {
  id: string;
  site_name: string;
  customer_name: string;
  mobile_no: string;
  billing_address: string;
  invoice_amt: number | null;
  received: number | null;
  po_vendor: number | null;
  po_labour: string | number;
  pe_transport: string | number;
  current_balance: number;
  pe_vendor: number | null;
  pe_labour: string | number;
  available_for_po: string;
}

interface SiteDetails {
  vendor_name: string;
  contact_name: string;
  contact_no: string;
  billing_address: string;
  gst_number: string;
}

interface Errors {
  vendor_name?: string;
  contact_name?: string;
  contact_no?: string;
  billing_address?: string;
  gst_number?: string;
}

const SiteDetails = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const location = useLocation();
  const [modalTitle, setModalTitle] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState("Quotation");
  const { Column, HeaderCell, Cell } = Table;
  const [vendors, setVendors] = useState<Vendor1[]>([]);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [tableData, setTableData] = useState<SiteData[]>([]);
  const [podata, setpodata] = useState<PoData[]>([]);
  const [carddata, setcarddata] = useState<Sitecard | null>(null);
  const [vendordata, setvendordata] = useState<Vendor[]>([]);
  const [pedata, setpedata] = useState<PeData[]>([]);
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showvendormodal, setShowvendormodal] = useState(false);
  const [showcreditmodal, setShowcreditmodal] = useState(false);
  const [quodata, setquodata] = useState<quo[]>([]);
  const [rtdata, setrtdata] = useState<RtData[]>([]);
  const [productsdata, setprodcutsdata] = useState<products[]>([]);
  const [invoicedata, setinvoicedata] = useState<invoice[]>([]);
  const { siteId } = useParams<{ siteId: string }>();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const navigate = useNavigate();
  const [fileType, setFileType] = useState("");
  const [markdata, setmarkdata] = useState("");

  const [rowData, setRowData] = useState<RowData | null>(null);
  const [customerBalance, setCustomerBalance] = useState("0");
  const [paymentVendor, setPaymentVendor] = useState("0");
  const [paymentTransport, setPaymentTransport] = useState("0");
  const [paymentLabour, setPaymentLabour] = useState("0");
  const [received1, setreceived] = useState("0");
  const [pevendor1, setPeVendor] = useState("0");
  const [pelabour1, setPeLabour] = useState("0");
  const [invvalue, setinvvalue] = useState("0");
  const [currbal, setcurrbalance] = useState("0");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [siteDetails, setSiteDetails] = useState({
    vendor_name: "",
    contact_name: "",
    contact_no: "",
    billing_address: "",
    gst_number: "",
  });
  const [sitecreditdetails, setsitecreditdetails] = useState({
    amount: "",
  });
  const [base64String, setBase64String] = useState<string | null>(null);

  useEffect(() => {
    getSitelist();
    getpolist();
    getpelist();
    getinvoice();
    getproducts();
    getvendor();
    getquoselect();
    getreturnslip();
    getsitecarddata();
    getvendorlist();
  }, []);
  useEffect(() => {
    console.log("log data", tableData);
    localStorage.setItem("siteid", siteId || "");
  }, [tableData]);

  const getSitelist = () => {
    callApi("post", "site_list.php", "")
      .then((res: any) => res.data)
      .then((response: any) => {
        console.log("sit response", response);
        if (response["response_Code"] == 200) {
          console.log("response", response, siteId);

          const filteredData = response.responseJson.filter(
            (item: any) => item.id === siteId
          );
          console.log("----------", filteredData);
          setTableData(filteredData);

          localStorage.setItem("shipadd", filteredData[0]?.shipping_address);
          localStorage.setItem("cusname", filteredData[0].customer_name);
          localStorage.setItem("cusadd", filteredData[0].billing_address);
          setmarkdata(filteredData[0].completed_site_status);
          console.log("sdfdsgdsg", filteredData[0].customer_name);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };

  const getvendorlist = () => {
    callApi("post", "vendor_list.php", "")
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          const filteredData = res.data.responseJson.filter(
            (item: any) => item.vendor_status === "1"
          );
          setVendors(filteredData);
          console.log("vendor_status", filteredData);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };

  const getreturnslip = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "return_select.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          setrtdata(res.data.responseJson);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };

  const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
    setPage(1);
    setLimit(dataKey);
  };
  const getsitecarddata = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "site_dashboard.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          const data = res.data.response[0];
          console.log("hdfdsf", data);
          setCustomerBalance(data.customer_balance ?? "0");
          setPaymentVendor(data.po_vendor ?? "0");
          setPaymentTransport(data.pe_transport ?? "0");
          setPaymentLabour(data.po_labour ?? "0");
          setcurrbalance(data.current_balance ?? "0");
          setPeLabour(data.pe_labour ?? "0");
          setPeVendor(data.pe_vendor ?? "0");
          setreceived(data.received ?? "0");
          setinvvalue(data.invoice_amt ?? "0");
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };

  const getpolist = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "po_select.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          setpodata(res.data.responseJson);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };
  const getpelist = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "pe_select_entry.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          console.log("pe data-------", res.data.responseJson);
          const vendorDataData = res.data.responseJson.map(
            (item: any, index: number) => {
              return {
                ...item,
                sno: (index + 1).toString(), // Assigning sno dynamically
              };
            }
          );
          setpedata(res.data.responseJson);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };
  const getproducts = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "product_table.php", req)
      .then((res: any) => {
        if (res.data && res.data["responseCode"] === 200) {
          console.log("vendor-------", res.data.responseJson);
          setprodcutsdata(res.data.responseJson);
          localStorage.setItem("siteId", siteId || "");
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };
  const convertToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64 = reader.result as string;
      setBase64String(base64);
      setFileType(file.type);
      console.log(base64, file.type);
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64: ", error);
    };
  };
  const handleVendorChange = (e: any) => {
    const vendorId = e.target.value;
    setSelectedVendor(vendorId);
    console.log("Selected Vendor ID:", vendorId); // Log the selected vendor ID
  };
  const getinvoice = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "invoice_select.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          console.log("invoice------", res.data.responseJson);
          setinvoicedata(res.data.responseJson);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };
  const getvendor = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "vendor_site_table.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          setvendordata(res.data.responseJson);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };
  const getquoselect = () => {
    const req = {
      site_id: siteId,
    };
    callApi("post", "quo_select.php", req)
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          setquodata(res.data.responseJson);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    const payload = {
      site_id: siteId,
      vendor_id: selectedVendor,
    };
    callApi("post", "vendor_credit_add.php", payload)
      .then((res) => {
        if (res.data && res.data["responseCode"] === 200) {
          showSuccess("Success", res.data.message);
          handleCloseModal();
          getvendor();
        } else {
          console.error("Failed to save data");
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
  };

  const handleSiteClick = (rowData: any) => {
    if (rowData.labour_status == "1") {
      navigate(`/layout/poform/${rowData.id}`);
    } else {
      navigate(`/layout/polabour/${rowData.id}`);
    }
  };
  const handleSitedeleteClick = async (rowData: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      const req = {
        quo_id: rowData.id,
      };
      callApi("post", "quo_delete.php", req)
        .then((res: any) => {
          if (res.data && res.data["responseCode"] === 200) {
            showSuccess("success !", res.data.message);
            getquoselect();
          } else {
            console.error("Failed to fetch site list");
          }
        })
        .catch((error) => {
          console.error("Error fetching site list:", error);
        });
    }
  };
  const handlertdeleteclick = async (rowData: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      const req = {
        id: rowData.id,
      };
      callApi("post", "return_delete.php", req)
        .then((res: any) => {
          if (res.data && res.data["responseCode"] === 200) {
            showSuccess("success !", res.data.message);
            getreturnslip();
          } else {
            console.error("Failed to fetch site list");
          }
        })
        .catch((error) => {
          console.error("Error fetching site list:", error);
        });
    }
  };
  const handlesiteclick = (rowData: any) => {
    navigate(`/layout/purchaseorder/${rowData.id}`);
  };

  const handleReturnShioClick = (rowData: any) => {
    navigate(`/layout/returnslippdf/${rowData.id}`);
  };
  const handleCloseModal = () => {
    setShowModalAdd(false);
  };
  const handleClosevendorModal = () => {
    setShowvendormodal(false);
  };
  const handleClosecreditModal = () => {
    setShowcreditmodal(false);
  };
  const initialSiteDetails = {
    vendor_name: "",
    contact_no: "",
    gst_number: "",
    contact_name: "",
    billing_address: "",
  };

  const [errors, setErrors] = useState<Errors>({});

  const validateField = (name: string, value: string) => {
    let error = "";

    switch (name) {
      case "vendor_name":
        if (!value) {
          error = "Vendor Name is required.";
        } else if (!/^[A-Za-z0-9\s.-]+$/.test(value)) {
          error = "Vendor Name can only contain letters.";
        }
        break;
      case "contact_name":
        if (!value) {
          error = "Contact Name is required.";
        } else if (!/^[A-Za-z\s]+$/.test(value)) {
          error = "Contact Name can only contain letters.";
        }
        break;
      case "contact_no":
        if (!value) {
          error = "Contact Number is required.";
        } else if (!/^\d{10}$/.test(value)) {
          error = "Contact Number must be 10 digits.";
        }
        break;
      case "gst_number":
        if (
          !/^([0][1-9]|[1-2][0-9]|[3][0-7])([A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})+$/.test(
            value
          )
        ) {
          error = "Invalid GST Number format.";
        }
        break;
      case "billing_address":
        if (!value) {
          error = "Billing Address is required.";
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setSiteDetails({ ...siteDetails, [name]: value });
    validateField(name, value);
    setsitecreditdetails({ ...sitecreditdetails, [name]: value });
  };

  const validate = () => {
    let valid = true;
    let errors: Errors = {};

    if (!siteDetails.vendor_name) {
      valid = false;
      errors.vendor_name = "Vendor Name is required.";
    } else if (!/^[A-Za-z0-9 .\- ]+$/.test(siteDetails.vendor_name)) {
      valid = false;
      errors.vendor_name = "Vendor Name can only contain letters.";
    }

    if (!siteDetails.contact_name) {
      valid = false;
      errors.contact_name = "Contact Name is required.";
    } else if (!/^[A-Za-z\s]+$/.test(siteDetails.contact_name)) {
      valid = false;
      errors.contact_name = "Contact Name can only contain letters.";
    }

    if (!siteDetails.contact_no) {
      valid = false;
      errors.contact_no = "Contact Number is required.";
    } else if (!/^\d{10}$/.test(siteDetails.contact_no)) {
      valid = false;
      errors.contact_no = "Contact Number must be 10 digits.";
    }

    if (siteDetails.gst_number) {
      if (
        !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(
          siteDetails.gst_number
        )
      ) {
        valid = false;
        errors.gst_number = "Invalid GST Number format.";
      }
    }

    if (!siteDetails.billing_address) {
      valid = false;
      errors.billing_address = "Billing Address is required.";
    }

    setErrors(errors);
    return valid;
  };

  const handleSubmitone = () => {
    if (validate()) {
      handleSave();
    }
  };

  const handleSave = () => {
    const req = {
      vendor_name: siteDetails.vendor_name,
      contact_no: siteDetails.contact_no,
      gst_number: siteDetails.gst_number,
      contact_name: siteDetails.contact_name,
      billing_address: siteDetails.billing_address,
    };

    callApi("post", "vendor_create.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          showSuccess("Success", "Added Successfully");
          getSitelist();
          getpolist();
          getpelist();
          getinvoice();
          getproducts();
          getvendor();
          getquoselect();
          getreturnslip();
          getsitecarddata();
          getvendorlist();
          handleClosevendorModal();
          setSiteDetails(initialSiteDetails);
          setShowModal(true);
        } else if (response.error === true) {
          showError("Error", response.message);
        }
      })
      .catch((error) => {
        console.error("Error saving site:", error);
      });
    handleCloseModal();
  };

  const handlecreditSave = () => {
    console.log("row data", rowData);
    const req = {
      credit_amt: sitecreditdetails.amount,
      vendor_id: rowData?.vendor_id,
      site_id: siteId,
    };

    callApi("post", "vendor_credit_add.php", req)
      .then((res) => {
        if (res.data && res.data["responseCode"] === 200) {
          showSuccess("Success", res.data.message);
          handleClosecreditModal();
          getvendor();
        } else {
          console.error("Failed to save data");
        }
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });
    handleCloseModal();
  };

  const handlepodeleteClick = async (rowData: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      const req = {
        po_id: rowData.id,
      };
      callApi("post", "po_delete.php", req)
        .then((res: any) => {
          if (res.data && res.data["response_Code"] === 200) {
            showSuccess("success !", res.data.message);
            getpolist();
          } else {
            console.error("Failed to fetch site list");
          }
        })
        .catch((error) => {
          console.error("Error fetching site list:", error);
        });
    }
  };

  const handlepedeleteClick = async (rowData: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      const req = {
        pe_id: rowData.pe_id,
      };
      callApi("post", "pe_delete.php", req)
        .then((res: any) => {
          if (res.data && res.data["responseCode"] === 200) {
            showSuccess("success !", res.data.message);
            getSitelist();
            getpolist();
            getpelist();
            getinvoice();
            getproducts();
            getvendor();
            getquoselect();
            getreturnslip();
            getsitecarddata();
            getvendorlist();
          } else {
            console.error("Failed to fetch site list");
          }
        })
        .catch((error) => {
          console.error("Error fetching site list:", error);
        });
    }
  };
  const handleinvdeleteClick = async (rowData: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      const req = {
        inv_id: rowData.id,
      };
      callApi("post", "inv_delete.php", req)
        .then((res: any) => {
          if (res.data && res.data["responseCode"] === 200) {
            showSuccess("success !", res.data.message);
            getSitelist();
            getpolist();
            getpelist();
            getinvoice();
            getproducts();
            getvendor();
            getquoselect();
            getreturnslip();
            getsitecarddata();
            getvendorlist();
          } else {
            console.error("Failed to fetch site list");
          }
        })
        .catch((error) => {
          console.error("Error fetching site list:", error);
        });
    }
  };
  const handlertbilldeleteClick = async (rowData: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      const req = {
        id: rowData.id,
      };
      callApi("post", "return_bill_delete.php", req)
        .then((res: any) => {
          if (res.data && res.data["responseCode"] === 200) {
            showSuccess("success !", res.data.message);
            getSitelist();
            getpolist();
            getpelist();
            getinvoice();
            getproducts();
            getvendor();
            getquoselect();
            getreturnslip();
            getsitecarddata();
            getvendorlist();
          } else {
            console.error("Failed to fetch site list");
          }
        })
        .catch((error) => {
          console.error("Error fetching site list:", error);
        });
    }
  };

  const handlepeclick = (rowData: any) => {
    navigate(`/layout/purchaseentryform1/${rowData.pe_id}`);
  };

  const handlepeClick = (rowData: any) => {
    navigate(`/layout/purchaseentry/${rowData.pe_id}`);
  };

  const handleaddcredit = (rowData: any) => {
    console.log("rowData---->", rowData);
    setRowData(rowData);
    console.log("setRowData--->", rowData);

    setShowcreditmodal(true);
  };
  const handlequoclick = (rowData: any) => {
    navigate(`/layout/leads/${rowData.id}`);
  };
  // const handleChange = (
  //   e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = e.target;
  //   setSiteDetails({ ...siteDetails, [name]: value });
  //   setsitecreditdetails({ ...sitecreditdetails, [name]: value });
  // };

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { name, value } = e.target;
  //   setSiteDetails({ ...siteDetails, [name]: value });
  //   setsitecreditdetails({ ...sitecreditdetails, [name]: value });
  // };

  const handlequopdf = (rowData: any) => {
    navigate(`/layout/quotationpdf/${rowData.id}`);
  };
  const handleinvoicepre = (rowData: any) => {
    navigate(`/layout/invoiceform/${rowData.id}`);
  };
  const handleinvoicetab = (rowData: any) => {
    navigate(`/layout/invoice/${rowData.id}`);
  };
  const handlertclick = (rowData: any) => {
    navigate(`/layout/returnslipupdate/${rowData.id}`);
  };

  const getpoData = () => {
    let filteredData = podata;

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn] as string | number;
        let y = b[sortColumn] as string | number;

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };
  const getpeData = () => {
    let filteredData = pedata;

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn] as string | number;
        let y = b[sortColumn] as string | number;

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };
  const getqoData = () => {
    let filteredData = quodata;

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn] as string | number;
        let y = b[sortColumn] as string | number;

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };
  const getinvData = () => {
    let filteredData = invoicedata;

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn] as string | number;
        let y = b[sortColumn] as string | number;

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };
  const getvendorData = () => {
    let filteredData = vendordata;

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn] as string | number;
        let y = b[sortColumn] as string | number;

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };
  const getproductData = () => {
    let filteredData = productsdata;

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn] as string | number;
        let y = b[sortColumn] as string | number;

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data product: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };
  const getrtData = () => {
    let filteredData = rtdata;

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn] as string | number;
        let y = b[sortColumn] as string | number;

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };

  const downloadCSV1 = () => {
    const data = [
      ["S.No", "Po Number", "Vendor", "PO Value"],
      ...podata.map((row, index) => [
        index + 1,
        row.po_number,
        row.vendor_name,
        row.po_value,
      ]),
    ];

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add title row
    const titleRow = worksheet.addRow(["Purchase Order"]);
    worksheet.mergeCells("A1:D1");
    const titleCell = worksheet.getCell("A1");
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell.font = { bold: true, size: 14 };

    // Add header row
    const headerRow = worksheet.addRow(data[0]);
    headerRow.eachCell((cell, colNumber) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" }, // Adjusted color for visibility
      };
    });

    // Add data rows
    data.slice(1).forEach((row) => {
      worksheet.addRow(row);
    });

    // Adjust the column widths for better visibility
    worksheet.columns = [
      { width: 10 },
      { width: 20 },
      { width: 30 },
      { width: 20 },
    ];

    // Write the workbook to an Excel file
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getFullYear()}`;
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Purchase_order_${formattedDate}.xlsx`;
      link.click();
    });
  };

  const downloadCSV2 = () => {
    const data = [
      ["SNo", "Po Number", "Pe Number", "Vendor", "PE value"],
      ...pedata.map((row, index) => [
        index + 1,
        row.po_number,
        row.pe_number,
        row.vendor_name,
        row.pe_value,
      ]),
    ];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add title row
    const titleRow = worksheet.addRow(["Purchase Entry"]);
    worksheet.mergeCells("A1:D1");
    const titleCell = worksheet.getCell("A1");
    titleCell.alignment = { horizontal: "center", vertical: "middle" };
    titleCell.font = { bold: true, size: 14 };

    // Add header row
    const headerRow = worksheet.addRow(data[0]);
    headerRow.eachCell((cell, colNumber) => {
      cell.alignment = { horizontal: "center", vertical: "middle" };
      cell.font = { bold: true };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" }, // Adjusted color for visibility
      };
    });

    // Add data rows
    data.slice(1).forEach((row) => {
      worksheet.addRow(row);
    });

    // Adjust the column widths for better visibility
    worksheet.columns = [
      { width: 10 },
      { width: 20 },
      { width: 30 },
      { width: 20 },
    ];

    // Write the workbook to an Excel file
    const currentDate = new Date();
    const formattedDate = `${currentDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${currentDate.getFullYear()}`;
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Purchase_order_${formattedDate}.xlsx`;
      link.click();
    });
  };
  const downloadCSV3 = () => {
    const data = [
      ["SNo", "Vendor Name", "Overall Credit", "PE Value", "Balance Credit"],
      ...vendordata.map((row, index) => [
        index + 1,
        row.vendor_name,
        row.overall_credit,
        row.pe_value,
        row.balance_credits,
      ]),
    ];

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Vendor_details.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadCSV4 = () => {
    const data = [
      ["SNo", "Product Name", "Vendor", "Qty", "Total Price"],
      ...productsdata.map((row, index) => [
        index + 1,
        row.product_name,
        row.vendor_name,
        row.qty,
        row.total_amt,
      ]),
    ];

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Product_details.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handlertupload = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowData: any
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      console.log("File selected for upload:", file);
      console.log("Row data:", rowData);

      // Create a FileReader to read the file as a base64-encoded string
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64File = reader.result as string; // base64-encoded file

        // Example: Create a FormData object and send it to an API endpoint
        const formData = new FormData();
        formData.append("file", base64File);
        formData.append("rowId", rowData.id);

        // Replace this with your API call
        const req = {
          id: rowData.id,
          fileType: file.type,
          bill_upload: base64File,
        };
        callApi("post", "return_bill_upload.php", req)
          .then((res: any) => {
            if (res.data && res.data["response_Code"] === 200) {
              showSuccess("Success", res.data.message);
            } else {
              console.error("Failed to upload file");
            }
          })
          .catch((error) => {
            console.error("Error uploading file:", error);
          });
      };

      // Read the file as a base64-encoded string
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handlertpreview = (rowData: any) => {
    console.log("fdsgsd", rowData);
    if (rowData.bill !== "") {
      window.open(rowData.bill, "_blank");
    }
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };

  const handleSetTab = (activeTab: any) => {
    setActiveTab(activeTab);
    console.log(activeTab);
  };

  const handlesitedetailsstatement = () => {
    navigate("/layout/statement");
  };
  const handlemarkcomplete = () => {
    const req = {
      id: siteId,
    };
    callApi("post", "site_complete_status.php", req)
      .then((res: any) => {
        if (res.data && res.data["responseCode"] === 200) {
          showSuccess("Success!", res.data.message);
          navigate("/layout/completedsite");
        } else if (res.data.success === false) {
          showError("Error", res.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };
  const handlegoBack = () => {
    navigate(`/layout/sitelist`);
  };

  const addUser = () => {
    setShowModalAdd(true);
  };

  const addVendor = () => {
    setShowvendormodal(true);
  };

  const handlequotclick = (rowData1: any) => {
    navigate(`/layout/leads/0`, { state: { rowData1 } });
  };
  const handleimgpreview = (rowData: any) => {
    console.log("fdsgsd", rowData);
    if (rowData.bill !== "") {
      window.open(rowData.bill, "_blank");
    }
  };

  return (
    <>
      <div className="tabletitle" style={{ fontSize: "24px", padding: "20px" }}>
        Site Details
      </div>
      <div className="sitedetailsbtn">
        {" "}
        <button className="btn excelbtn mr-2" onClick={handlegoBack}>
          Go Back
        </button>
        <div>
          <button
            type="button"
            className="btn excelbtn"
            onClick={() => handlesitedetailsstatement()}
          >
            Statement
          </button>
          {markdata === "0" && (
            <button
              type="button"
              className="btn mark-btn excelbtn "
              onClick={() => handlemarkcomplete()}
            >
              Mark as Completed
            </button>
          )}
        </div>
      </div>
      <div className="new-vendorlist-card">
        <Grid fluid>
          <Row className="new-vendorlist-row">
            <Col xs={12} md={4} className="new-vendorlist-col">
              <div className="vendor-fields1">
                <p className="field-label">Site Name :</p>
                <span className="field-value">
                  {tableData[0] ? tableData[0].name : "Loading..."}
                </span>
              </div>
              <div className="vendor-fields1">
                <p className="field-label">Customer Name :</p>
                <span className="field-value">
                  {tableData[0] ? tableData[0].customer_name : "Loading..."}
                </span>
              </div>
              <div className="vendor-fields1">
                <p className="field-label">Contact Details :</p>
                <span className="field-value">
                  {tableData[0] ? tableData[0].contact : "Loading..."}
                </span>
              </div>
            </Col>
            <Col xs={12} md={4} className="new-vendorlist-col">
              <div className="vendor-fields">
                <p className="field-label">Billing Address :</p>
                <span
                  className="field-value"
                  dangerouslySetInnerHTML={{
                    __html: tableData[0]
                      ? tableData[0].billing_address
                      : "Loading...",
                  }}
                />
              </div>
            </Col>
            <Col xs={12} md={4} className="new-vendorlist-col">
              <div className="vendor-fields">
                <p className="field-label">Shipping Address :</p>
                <span
                  className="field-value"
                  dangerouslySetInnerHTML={{
                    __html: tableData[0]
                      ? tableData[0].shipping_address
                      : "Loading...",
                  }}
                />
              </div>
            </Col>
          </Row>
        </Grid>
      </div>

      <div className="card-container">
        <>
          <div className="card-section">
            {" "}
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Invoice Value</span>
                <img src={balance} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">{invvalue}</h1>
            </a>
          </div>

          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Received Payment</span>
                <img src={received} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">{received1}</h1>
            </a>
          </div>
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">PO-Vendor</span>
                <img src={vendor} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">{paymentVendor}</h1>
            </a>
          </div>
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">PO-Labour</span>
                <img src={labour} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">{paymentLabour}</h1>
            </a>
          </div>
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">PE-Transport</span>
                <img
                  src={petransport}
                  alt="Total Leads"
                  className="leads-icon"
                />
              </div>
              <h1 className="card-info">{paymentTransport}</h1>
            </a>
          </div>
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">Customer Balance</span>
                <img src={balance} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">{currbal}</h1>
            </a>
          </div>
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">PE-Vendor</span>
                <img src={pevendor} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">{pevendor1}</h1>
            </a>
          </div>
          <div className="card-section">
            <a style={{ color: "black", textDecoration: "none" }}>
              <div className="card-details">
                <span className="card-title">PE-Labour</span>
                <img src={pelabour} alt="Total Leads" className="leads-icon" />
              </div>
              <h1 className="card-info">{pelabour1}</h1>
            </a>
          </div>
        </>
      </div>

      <div className="container-qt">
        <Tabs defaultActiveKey="1">
          <Tabs.Tab
            eventKey="1"
            title={
              <div onClick={() => handleSetTab("Quotation")}>Quotation</div>
            }
            icon={<TextImageIcon onClick={() => handleSetTab("Quotation")} />}
          ></Tabs.Tab>
          <Tabs.Tab
            eventKey="2"
            title={<div onClick={() => handleSetTab("Invoice")}>Invoice</div>}
            icon={<PageIcon onClick={() => handleSetTab("Invoice")} />}
          ></Tabs.Tab>
          <Tabs.Tab
            eventKey="3"
            title={
              <div onClick={() => handleSetTab("Purchase Order")}>
                Purchase Order
              </div>
            }
            icon={
              <TextImageIcon onClick={() => handleSetTab("Purchase Order")} />
            }
          ></Tabs.Tab>
          <Tabs.Tab
            eventKey="4"
            title={
              <div onClick={() => handleSetTab("Product Entry")}>
                Purchase Entry
              </div>
            }
            icon={<DetailIcon onClick={() => handleSetTab("Product Entry")} />}
          ></Tabs.Tab>

          <Tabs.Tab
            eventKey="5"
            title={<div onClick={() => handleSetTab("Vendor")}>Vendor</div>}
            icon={<AdminIcon onClick={() => handleSetTab("Vendor")} />}
          ></Tabs.Tab>
          <Tabs.Tab
            eventKey="6"
            title={<div onClick={() => handleSetTab("Products")}>Products</div>}
            icon={<StorageIcon onClick={() => handleSetTab("Products")} />}
          ></Tabs.Tab>
          <Tabs.Tab
            eventKey="7"
            title={
              <div onClick={() => handleSetTab("Returnslip")}>Return Slip</div>
            }
            icon={<TextImageIcon onClick={() => handleSetTab("Returnslip")} />}
          ></Tabs.Tab>
        </Tabs>
        {activeTab == "Purchase Order" && (
          <div
            className="tab-content"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <div className="table-header " style={{ height: "30px" }}>
              <Link to="/layout/poformcreate/0">
                <button
                  type="button"
                  className="btn excelbtn addpo"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Add
                </button>
              </Link>
              <button
                type="button"
                className="btn excelbtn "
                style={{ marginRight: "10px" }}
                onClick={downloadCSV1}
              >
                Excel
              </button>
            </div>
            <Table
              height={300}
              data={getpoData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={100} align="center">
                <HeaderCell>S No.</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) =>
                    rowIndex !== undefined ? rowIndex + 1 : ""
                  }
                </Cell>
              </Column>

              <Column width={250}>
                <HeaderCell>PO Number</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      onClick={() => handlesiteclick(rowData)}
                      className="underline-qt"
                    >
                      {rowData.po_number}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={240}>
                <HeaderCell>Vendor</HeaderCell>
                <Cell dataKey="vendor_name" />
              </Column>

              <Column width={200}>
                <HeaderCell>PO Value</HeaderCell>
                <Cell dataKey="po_value" />
              </Column>

              <Column width={200} align="center">
                <HeaderCell>Action</HeaderCell>
                <Cell width={100}>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      {rowData.pe_status === "0" && (
                        <>
                          <button
                            className="iconbg"
                            onClick={() => handleSiteClick(rowData)}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              style={{ height: "20px", width: "20px" }}
                            />
                          </button>
                          <button
                            className="iconbg"
                            onClick={() => handlepodeleteClick(rowData)}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ height: "20px", width: "20px" }}
                            />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={podata.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        )}
        {activeTab == "Product Entry" && (
          <div
            className="tab-content"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <div className="table-header " style={{ height: "30px" }}>
              <button
                type="button"
                className="btn excelbtn"
                style={{ marginRight: "10px", float: "right" }}
                onClick={downloadCSV2}
              >
                Excel
              </button>
            </div>
            <Table
              height={300}
              data={getpeData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={100} align="center">
                <HeaderCell>S No.</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) =>
                    rowIndex !== undefined ? rowIndex + 1 : ""
                  }
                </Cell>
              </Column>

              <Column width={150}>
                <HeaderCell>PE Number</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      onClick={() => handlepeClick(rowData)}
                      className="underline-qt"
                    >
                      {rowData.pe_number}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={150}>
                <HeaderCell>PO Number</HeaderCell>
                <Cell dataKey="po_number" />
              </Column>

              <Column width={150}>
                <HeaderCell>Vendor</HeaderCell>
                <Cell dataKey="vendor_name" />
              </Column>

              <Column width={150}>
                <HeaderCell>PE Value</HeaderCell>
                <Cell dataKey="pe_value" />
              </Column>

              <Column width={130}>
                <HeaderCell>PO Value</HeaderCell>
                <Cell dataKey="po_value" />
              </Column>
              <Column width={130}>
                <HeaderCell
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Bill
                </HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      {rowData.bill_type !== "" && (
                        <button
                          className="iconbg"
                          onClick={() => handleimgpreview(rowData)}
                        >
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{ height: "20px", width: "20px" }}
                          />
                        </button>
                      )}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={150} align="center">
                <HeaderCell>Action</HeaderCell>
                <Cell width={100}>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <button
                        className="iconbg"
                        onClick={() => handlepeclick(rowData)}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                      <button
                        className="iconbg"
                        onClick={() => handlepedeleteClick(rowData)}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={pedata.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        )}
        {activeTab == "Invoice" && (
          <div
            className="tab-content"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <Table
              height={300}
              data={getinvData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={100} align="center">
                <HeaderCell>S.No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) =>
                    rowIndex !== undefined ? rowIndex + 1 : ""
                  }
                </Cell>
              </Column>

              <Column width={250}>
                <HeaderCell>Invoice Number</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      onClick={() => handleinvoicetab(rowData)}
                      className="underline-qt"
                    >
                      {rowData.inv_no}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={240}>
                <HeaderCell>Payment Received</HeaderCell>
                <Cell dataKey="payment_received" />
              </Column>

              <Column width={200}>
                <HeaderCell>Invoice Amount</HeaderCell>
                <Cell dataKey="invoice_amt" />
              </Column>

              <Column width={200}>
                <HeaderCell>Balance</HeaderCell>
                <Cell dataKey="invoice_bal" />
              </Column>

              <Column width={200} align="center">
                <HeaderCell>Action</HeaderCell>
                <Cell width={100}>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <button
                        className="iconbg"
                        onClick={() => handleinvoicepre(rowData)}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                      <button
                        className="iconbg"
                        onClick={() => handleinvdeleteClick(rowData)}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={invoicedata.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        )}
        {activeTab == "Quotation" && (
          <div
            className="tab-content"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <button
              type="button"
              className="btn excelbtn addpo vendor-add-btn"
              style={{ marginTop: "10px", marginBottom: "10px" }}
              onClick={() => handlequotclick(tableData)}
            >
              Add
            </button>
            <Table
              height={300}
              data={getqoData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={100} align="center">
                <HeaderCell>S.No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) =>
                    rowIndex !== undefined ? rowIndex + 1 : ""
                  }
                </Cell>
              </Column>

              <Column width={250}>
                <HeaderCell>Quote Number</HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      onClick={() => handlequopdf(rowData)}
                      className="underline-qt"
                    >
                      {rowData.quo_no}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={250}>
                <HeaderCell>Amount</HeaderCell>
                <Cell dataKey="quo_amt" />
              </Column>

              <Column width={250} align="center">
                <HeaderCell>Action</HeaderCell>
                <Cell width={100}>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <button
                        className="iconbg"
                        onClick={() => handlequoclick(rowData)}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                      <button
                        className="iconbg"
                        onClick={() => handleSitedeleteClick(rowData)}
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={quodata.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        )}
        {activeTab == "Vendor" && (
          <div
            className="tab-content"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <div className="table-header " style={{ height: "30px" }}>
              <button
                type="button"
                className="btn excelbtn"
                style={{ marginRight: "10px", float: "left" }}
                onClick={addUser}
              >
                Add
              </button>
              <button
                type="button"
                className="btn excelbtn"
                style={{ marginRight: "10px", float: "right" }}
                onClick={downloadCSV3}
              >
                Excel
              </button>
            </div>
            <Table
              height={300}
              data={getvendorData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={100} align="center">
                <HeaderCell>S.No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) =>
                    rowIndex !== undefined ? rowIndex + 1 : ""
                  }
                </Cell>
              </Column>

              <Column width={240}>
                <HeaderCell>Vendor Name</HeaderCell>
                <Cell dataKey="vendor_name" />
              </Column>

              <Column width={250}>
                <HeaderCell>Overall Credit</HeaderCell>
                <Cell dataKey="overall_credit" />
              </Column>

              <Column width={200}>
                <HeaderCell>PE Value</HeaderCell>
                <Cell dataKey="pe_value" />
              </Column>

              <Column width={200}>
                <HeaderCell>Balance Credit</HeaderCell>
                <Cell dataKey="balance_credits" />
              </Column>

              <Column width={200} align="center">
                <HeaderCell>Action</HeaderCell>
                <Cell width={100}>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <button
                        className="btn excelbtn addc-btn"
                        onClick={() => handleaddcredit(rowData)}
                      >
                        Add Credit
                      </button>
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={vendordata.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        )}
        {activeTab == "Products" && (
          <div
            className="tab-content"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <div className="table-header " style={{ height: "30px" }}>
              <button
                type="button"
                className="btn excelbtn"
                style={{ marginRight: "10px", float: "right" }}
                onClick={downloadCSV4}
              >
                Excel
              </button>
            </div>
            <Table
              height={300}
              data={getproductData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
            >
              <Column width={100} align="center" fixed>
                <HeaderCell>S.No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) =>
                    rowIndex !== undefined ? rowIndex + 1 : ""
                  }
                </Cell>
              </Column>

              <Column width={250}>
                <HeaderCell>Product Name</HeaderCell>
                <Cell dataKey="product_name" />
              </Column>

              <Column width={240}>
                <HeaderCell>Vendor</HeaderCell>
                <Cell dataKey="vendor_name" />
              </Column>

              <Column width={200}>
                <HeaderCell>QTY</HeaderCell>
                <Cell dataKey="qty" />
              </Column>

              <Column width={200} align="center">
                <HeaderCell>Price</HeaderCell>
                <Cell dataKey="price" />
              </Column>

              <Column width={200} align="center">
                <HeaderCell>Total Price</HeaderCell>
                <Cell dataKey="total_amt" />
              </Column>
            </Table>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={productsdata.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        )}
        {activeTab == "Returnslip" && (
          <div
            className="tab-content"
            style={{ width: "100%", overflow: "hidden" }}
          >
            <Table
              height={300}
              data={getrtData()}
              sortColumn={sortColumn}
              sortType={sortType}
              onSortColumn={handleSortColumn}
              loading={loading}
              style={{ width: "100%" }}
            >
              <Column width={100} align="center" fixed>
                <HeaderCell>S.No</HeaderCell>
                <Cell>
                  {(rowData, rowIndex) =>
                    rowIndex !== undefined ? rowIndex + 1 : ""
                  }
                </Cell>
              </Column>

              <Column width={200}>
                <HeaderCell>Date</HeaderCell>
                <Cell dataKey="rt_date" />
              </Column>

              <Column width={250}>
                <HeaderCell>Return Slip Number</HeaderCell>
                {/* <Cell dataKey="rt_no" /> */}
                <Cell>
                  {(rowData) => (
                    <div
                      onClick={() => handleReturnShioClick(rowData)}
                      className="underline-qt"
                    >
                      {rowData.rt_no}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={250}>
                <HeaderCell
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Acknowledgement
                </HeaderCell>
                <Cell>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      {rowData.bill_status === 0 ? (
                        <button onClick={triggerFileInput} className="iconbg">
                          <FontAwesomeIcon
                            icon={faUpload}
                            style={{ height: "20px", width: "20px" }}
                          />
                          <input
                            type="file"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={(event) => handlertupload(event, rowData)}
                          />
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={(event) => handlertpreview(rowData)}
                            className="iconbg"
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              style={{ height: "20px", width: "20px" }}
                            />
                          </button>
                          <button
                            className="iconbg"
                            onClick={() => handlertbilldeleteClick(rowData)}
                          >
                            <FontAwesomeIcon
                              icon={faTrash}
                              style={{ height: "20px", width: "20px" }}
                            />
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </Cell>
              </Column>

              <Column width={200} align="center">
                <HeaderCell>Action</HeaderCell>
                <Cell width={100}>
                  {(rowData) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "15px",
                      }}
                    >
                      <button
                        onClick={() => handlertclick(rowData)}
                        className="iconbg"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                      <button
                        onClick={() => handlertdeleteclick(rowData)}
                        className="iconbg"
                      >
                        <FontAwesomeIcon
                          icon={faTrashCan}
                          style={{ height: "20px", width: "20px" }}
                        />
                      </button>
                    </div>
                  )}
                </Cell>
              </Column>
            </Table>
            <div>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={rtdata.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        )}
        <Modal open={showModalAdd} onClose={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>
              {showModalAdd ? "Add Site Vendor" : "Edit Site Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <div className="form-align">
                <Form.Group controlId="formVendor" className="mb-10">
                  <Form.Label>Vendor</Form.Label>
                  <Form.Control
                    as="select"
                    name="vendor"
                    value={selectedVendor}
                    onChange={handleVendorChange}
                    required
                  >
                    <option value="">Select vendor</option>
                    {vendors.map((vendor) => (
                      <option key={vendor.id} value={vendor.id}>
                        {vendor.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <Modal.Footer>
                <Button
                  className="excelbtn vendor-btn vendor-add-btn"
                  style={{
                    backgroundColor: "#595959",
                    color: "white",
                    float: "left",
                  }}
                  onClick={addVendor}
                >
                  Add New Vendor
                </Button>
                <Button
                  className="excelbtn vendor-add-btn"
                  style={{
                    backgroundColor: "#595959 !important",
                    color: "white",
                  }}
                  onClick={handleCloseModal}
                >
                  Close
                </Button>
                <Button
                  className="excelbtn vendor-add-btn"
                  type="submit"
                  style={{ backgroundColor: "#007bff", color: "white" }}
                >
                  Save
                </Button>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>

        {/* <Modal open={showvendormodal} onClose={handleClosevendorModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>
              {showvendormodal ? "Add Vendor" : "Edit Site Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="form-align">
                <div className="row mb-10">
                  <div className="col">
                    <Form.Group controlId="formSiteName">
                      <Form.Label>Vendor Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter vendor name"
                        name="vendor_name"
                        value={siteDetails.vendor_name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group controlId="formCustomerName">
                      <Form.Label>Contact Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact name"
                        name="contact_name"
                        value={siteDetails.contact_name}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col">
                    <Form.Group controlId="formContact">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={10}
                        placeholder="Enter contact number"
                        name="contact_no"
                        value={siteDetails.contact_no}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group controlId="formGstNumber">
                      <Form.Label>GST Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter GST number"
                        name="gst_number"
                        value={siteDetails.gst_number}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col">
                    <Form.Group controlId="formBillingAddress">
                      <Form.Label>Billing Address</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter billing address"
                        name="billing_address"
                        value={siteDetails.billing_address}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="excelbtn"
              style={{ backgroundColor: "#595959", color: "white" }}
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="excelbtn"
              style={{ backgroundColor: "#007bff", color: "white" }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal> */}

        <Modal open={showvendormodal} onClose={handleClosevendorModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>
              {showvendormodal ? "Add Vendor" : "Edit Site Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="form-align">
                <div className="row mb-10">
                  <div className="col">
                    <Form.Group controlId="formSiteName">
                      <Form.Label>
                        Vendor Name<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter vendor name"
                        name="vendor_name"
                        minLength={1}
                        maxLength={30}
                        value={siteDetails.vendor_name}
                        onChange={handleChange}
                        required
                      />
                      {errors.vendor_name && (
                        <div className="error">{errors.vendor_name}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group controlId="formCustomerName">
                      <Form.Label>
                        Contact Name<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter contact name"
                        name="contact_name"
                        minLength={1}
                        maxLength={30}
                        value={siteDetails.contact_name}
                        onChange={handleChange}
                        required
                      />
                      {errors.contact_name && (
                        <div className="error">{errors.contact_name}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col">
                    <Form.Group controlId="formContact">
                      <Form.Label>
                        Contact Number<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={10}
                        placeholder="Enter contact number"
                        name="contact_no"
                        value={siteDetails.contact_no}
                        onChange={handleChange}
                        required
                      />
                      {errors.contact_no && (
                        <div className="error">{errors.contact_no}</div>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group controlId="formGstNumber">
                      <Form.Label>GST Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter GST number"
                        name="gst_number"
                        value={siteDetails.gst_number}
                        onChange={handleChange}
                        required
                      />
                      {errors.gst_number && (
                        <div className="error">{errors.gst_number}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
                <div className="row mb-10">
                  <div className="col">
                    <Form.Group controlId="formBillingAddress">
                      <Form.Label>
                        Billing Address<span style={{ color: "red" }}>*</span>
                      </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter billing address"
                        name="billing_address"
                        value={siteDetails.billing_address}
                        onChange={handleChange}
                        required
                      />
                      {errors.billing_address && (
                        <div className="error">{errors.billing_address}</div>
                      )}
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="excelbtn"
              style={{ backgroundColor: "#595959", color: "white" }}
              onClick={handleClosevendorModal}
            >
              Close
            </Button>
            <Button
              className="excelbtn"
              style={{ backgroundColor: "#007bff", color: "white" }}
              onClick={handleSubmitone}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal open={showcreditmodal} onClose={handleClosecreditModal}>
          <Modal.Header closeButton>
            <Modal.Title style={{ textAlign: "center" }}>
              {showcreditmodal ? "Credit" : "Edit Site Details"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="form-align">
                <div className="row mb-10">
                  <div className="col">
                    <Form.Group controlId="formSiteName">
                      <Form.Label>Site Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter vendor name"
                        name="vendor_name"
                        minLength={1}
                        maxLength={30}
                        value={tableData[0]?.name}
                        onChange={handleChange}
                        disabled
                      />
                    </Form.Group>
                  </div>
                  <div className="col">
                    <Form.Group controlId="formCustomerName">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Amount"
                        name="amount"
                        minLength={1}
                        maxLength={15}
                        value={sitecreditdetails.amount}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="excelbtn vendor-add-btn"
              style={{ backgroundColor: "#595959", color: "white" }}
              onClick={handleCloseModal}
            >
              Close
            </Button>
            <Button
              className="excelbtn vendor-add-btn"
              style={{ backgroundColor: "#007bff", color: "white" }}
              onClick={handlecreditSave}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default SiteDetails;
