import React, { useEffect, useState, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "./quatation.css";
import { useNavigate, useParams } from "react-router-dom";
import { showAlert, showError, showSuccess } from "../../Utils/sweetAlert";
import { callApi } from "../../Utils/Api";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEye,
  faPaperclip,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

interface Row {
  id: any;
  sno: number;
  description: string;
  length: string;
  h_w: string;
  area: string;
  totalPrice: string;
}
interface Less {
  id: any;
  sno: number;
  description: string;
  amount: any;
}

interface Table {
  name: string;
  rows: Row[];
}
interface LessTable {
  name: string;
  rows: Less[];
}
interface Item {
  item_id: string;
  product_id: string;
  product: string;
  po_qty: string;
  po_unit_price: string;
  po_total: string;
  pe_qty: string;
  pe_unit_price: string;
  pe_total: string;
}

function PurchaseEntryform1() {
  const navigate = useNavigate();
  const [customerName, setCustomerName] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerDate, setCustomerData] = useState("");
  const [modalContent, setModalContent] = useState<any>(null);
  const [receivedAmount, setReceivedAmount] = useState("");
  const [categories, setCategories] = useState<string[]>([]);
  const [rows, setRows] = useState<{ [category: string]: Row[] }>({});
  const [submitting, setSubmitting] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [showEditors, setShowEditors] = useState<{
    [category: string]: boolean;
  }>({});
  const [showModal, setShowModal] = useState(false);
  const { peid } = useParams<{ peid: string }>();
  const [tables, setTables] = useState<Table[]>([]);
  const [lessWork, setLessWork] = useState<LessTable[]>([]);
  const [ckeditorData, setCkeditorData] = useState<{
    [category: string]: string;
  }>({});
  const [amount, setTextInput] = useState("");
  const [productname, setproductname] = useState("");
  const [vendorid, setvendor_id] = useState("");
  const [laboutstatus, setlabourstatus] = useState("");
  const [state, setState] = useState({
    QuoteListSet: { category: [] as any[] },
    vendor: [
      {
        id: "",
        name: "",
      },
    ],
    item: [
      {
        id: "",
        product_name: "",
        product_price: "",
        po_qty: "",
      },
    ],
  });
  const [deletedCategoryItemIds, setDeletedCategoryItemIds] = useState<
    number[]
  >([]);
  const [remarks, setremarks] = useState("");
  const [poids, setpoid] = useState("");
  const [povalue, setpovalue] = useState("");
  const [deletedCategoryIds, setDeletedCategoryIds] = useState<number[]>([]);
  const [deletedLessWorkIds, setDeletedLessWorkIds] = useState<number[]>([]);
  const [deletedTableIds, setDeletedTableIds] = useState<number[]>([]);
  const [deletedIds, setDeletedIds] = useState<number[]>([]);
  const [CustomerVendor, setCustomerVendor] = useState("");
  const [siteId, setSiteId] = useState("");
  const [fileType, setFileType] = useState("");
  const [selecteditem, setselecteditem] = useState<Item[]>([]);

  useEffect(() => {
    console.log(
      deletedCategoryItemIds,
      deletedCategoryIds,
      deletedLessWorkIds,
      deletedTableIds
    );
  }, [
    deletedCategoryItemIds,
    deletedCategoryIds,
    deletedLessWorkIds,
    deletedTableIds,
  ]);

  useEffect(() => {
    getQuotatilDetails();
    GetVendorDetails();
    getPESelect();
  }, []);

  const getPESelect = () => {
    const req = {
      pe_id: peid,
    };
    callApi("post", "pe_id_select.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["response_Code"] === 200) {
          const data = response.responseJson;
          setState((prevState: any) => ({
            ...prevState,
            poData: response?.responseJson[0],
          }));
          setCustomerData(response?.responseJson[0]?.pe_date);
          setCustomerVendor(response?.responseJson[0]?.vendor_id);
          setSiteId(response?.responseJson[0]?.site_id);
          setCustomerName(response?.responseJson[0]?.pe_number);
          setItemData(response?.responseJson[0]?.items);
          setremarks(response?.responseJson[0].comments);
          setpoid(response?.responseJson[0].po_id);
          setpovalue(response?.responseJson[0].po_total_value);
        }
      });
  };

  const [isValid, setIsValid] = useState(false);
  const checkValid = () => {
    let valid = true;
    const updatedItemData = ItemData.map((element) => {
      if (element.pe_unit_price !== "" && element.pe_qty !== "") {
        return { ...element, valid: true };
      } else {
        valid = false;
        return { ...element, valid: false };
      }
    });
    setItemData(updatedItemData);
    return valid;
  };
  const submitHandelClick = async () => {
    const isValid = await checkValid();
    console.log("checkis valid------", isValid);
    const Grand_total = ItemData.reduce(
      (acc: any, curr: any) => acc + parseFloat(curr.pe_total || 0),
      0
    );
    const site_id = localStorage.getItem("siteid");
    const poo_id = localStorage.getItem("purchaseid");
    const labour_id = localStorage.getItem("labour_id");

    const req = {
      pe_id: peid,
      po_id: poids,
      po_value: povalue,
      po_number: customerName,
      vendor_id: CustomerVendor,
      po_date: customerDate,
      site_id: site_id,
      items: ItemData,
      selectedFile: selectedFile,
      bill_upload: base64String,
      fileType: fileType,
      grand_total: Grand_total,
      labour_status: labour_id,
      comments: remarks,
    };
    console.log("Grand_total---->", Grand_total);
    console.log("req---->", req);

    if (isValid) {
      setIsValid(false);
      callApi("post", "pe_entry.php", req)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response["responseCode"] === 200) {
            showSuccess("Success!", response.message);
            navigate(`/layout/purchaseentry/${peid}`);
          } else {
            showError("Failed", response.message);
          }
        });
    } else {
      setIsValid(true);
    }
    console.log("submitHandelClick", req);
  };

  const handleTextInputChange = (e: any) => {
    setTextInput(e.target.value);
  };

  const getQuotatilDetails = () => {
    const req = {
      quo_id: "1",
    };
    callApi("post", "quo_detail.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          const data = response.responseJson;
          setState((prevState) => ({
            ...prevState,
            QuoteListSet: response.responseJson,
          }));

          const categories = data.category.map((cat: any) => cat.categoryName);

          const rows = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.categoryItems.map(
              (item: any, index: any) => ({
                id: item.item_id,
                sno: index,
                description: item.description,
                length: item.length,
                h_w: item.height,
                area: item.area,
                totalPrice: item.totalPrice,
              })
            );
            return acc;
          }, {});

          const material = data.category.reduce((acc: any, cat: any) => {
            acc[cat.categoryName] = cat.material;
            return acc;
          }, {});

          const extraWork = data.extraWork.map((extra: any) => ({
            name: extra.categoryName,
            rows: extra.categoryItems.map((item: any, index: any) => ({
              id: item.item_id,
              sno: index,
              description: item.description,
              length: item.length,
              h_w: item.height,
              area: item.area,
              totalPrice: item.totalPrice,
            })),
          }));

          const lessWork = Array.isArray(data.lesswork)
            ? data.lesswork.map((less: any) => ({
                name: less.categoryName,
                rows: less.categoryItems.map((item: any, index: any) => ({
                  id: item.item_id,
                  sno: index,
                  description: item.description,
                  amount: item.amount,
                })),
              }))
            : [];
          setCategories(categories);
          setRows(rows);
          setTables(extraWork);
          setLessWork(lessWork);
          setCkeditorData(material);
          console.log("categories", categories);
          console.log("rows", rows);
          console.log("material", material);
          console.log("extraWork", extraWork);
          console.log("lessWork", lessWork);
        } else {
          setState((prevState: any) => ({
            ...prevState,
            QuoteListSet: [],
          }));
        }
      });
  };

  const GetVendorDetails = () => {
    callApi("POST", "vendor_list.php", "")
      .then((res) => res.data)
      .then((response) => {
        const filteredVendors = response.responseJson.filter(
          (vendor: any) => vendor.vendor_status === "1"
        );
        setState((prevState: any) => ({
          ...prevState,
          vendor: filteredVendors,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("vendor---name--->", state.vendor);

    callApi("POST", "product_list.php", "")
      .then((res) => res.data)
      .then((response) => {
        console.log("response--->222", response.responseJson);

        setState((prevState: any) => ({
          ...prevState,
          item: response.responseJson,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("vendor---item--->", state.item);
  };

  const [ItemData, setItemData] = useState([
    {
      Itemname: "",
      oty: "",
      unitprice: "",
      total: "",
      pe_qty: "",
      pe_unit_price: "",
    },
  ]);

  useEffect(() => {
    console.log("expenceData has changed:", ItemData);
  }, [ItemData]);

  const onchange = async (e: any, index: number) => {
    const fieldValue = e.target.value;
    const fieldName = e.target.name;
    const updatedExpenceData: any = [...ItemData];
    let newItem;

    if (laboutstatus !== "2") {
      if (fieldName === "pe_unit_price") {
        const isConfirm = await showAlert(
          "Please Confirm",
          "You are about to edit the PE Unit Price. Do you want to proceed?",
          "Yes",
          "No"
        );
        if (isConfirm) {
          // Open the modal with additional details
          setModalContent({
            itemIndex: index,
            oldPrice: updatedExpenceData[index].pe_unit_price,
          });
          setShowModal(true);
        } else {
          // User cancelled, revert the change or do nothing
          return;
        }
      }
    }

    if (fieldName == "pe_qty") {
      const pe_unit_price: any = updatedExpenceData[index].pe_unit_price;
      const pe_total = fieldValue * pe_unit_price;
      newItem = {
        ...updatedExpenceData[index],
        [fieldName]: fieldValue,
        pe_total,
        pe_unit_price: pe_unit_price ?? 0,
      };
    } else {
      newItem = { ...updatedExpenceData[index], [fieldName]: fieldValue };
    }
    // const newItem = { ...updatedExpenceData[index], [fieldName]: fieldValue };
    // newItem.valid = fieldValue !== "";
    updatedExpenceData[index] = newItem;
    setItemData(updatedExpenceData);
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [base64String, setBase64String] = useState<string | null>(null);

  const handleIconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleClose = () => setShowModal(false);

  const handleConfirm = () => {
    // Handle the confirmation action here
    const req = {
      vendor_id: vendorid,
      product_id: selecteditem,
      product_name: productname,
      new_price: amount,
    };
    callApi("post", "product_pe_update.php", req)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response["responseCode"] === 200) {
          showSuccess("Success", response.message);
          const data = response.responseJson;
          getPESelect();
        } else {
          console.error("Failed to Fetch");
        }
      })
      .catch((error) => {
        console.error("Error Fetching:", error);
      });

    setShowModal(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      console.log("file___", file, event.target);

      setSelectedFile(file);
      const fileUrl = URL.createObjectURL(file);
      setPreviewUrl(fileUrl);
      convertToBase64(file);
    }
  };

  const convertToBase64 = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const base64 = reader.result as string;
      setBase64String(base64);
      setFileType(file.type);
      console.log(base64, file.type);
    };
    reader.onerror = (error) => {
      console.error("Error converting file to Base64: ", error);
    };
  };

  const handlePreviewClick = () => {
    if (previewUrl) {
      window.open(previewUrl, "_blank");
    }
  };
  const handlegoBack = () => {
    navigate(-1);
  };
  return (
    <div className="container-qt">
      <button className="btn excelbtn mr-2" onClick={handlegoBack}>
        Go Back
      </button>
      <header className="head">
        <Row>
          <Col lg="2" md="2" sm="2" xs="2" className="flex"></Col>
          <Col lg="2" md="2" sm="2" xs="2" className="flex"></Col>
          <Col lg="4" md="4" sm="4" xs="4" className="flex">
            <h5>Add Purchase Entry</h5>
          </Col>
          <Col lg="2" md="2" sm="2" xs="2" className="flex"></Col>
          <Col lg="2" md="2" sm="2" xs="2" className="flex"></Col>
        </Row>
      </header>
      <div className="">
        <Row>
          <Col lg="2" md="2" sm="2" xs="2"></Col>
          <Col lg="4" md="4" sm="4" xs="4">
            <label>Purchase Entry number</label>
            <input
              type="text"
              value={customerName}
              disabled
              className="form-control "
              onChange={(e) => setCustomerName(e.target.value)}
              placeholder="Enter Purchase Entry number..."
            />
          </Col>
          <Col lg="4" md="4" sm="4" xs="4">
            <label>Date</label>
            <input
              type="date"
              className="form-control "
              value={customerDate}
              onChange={(e) => setCustomerData(e.target.value)}
              placeholder="Enter Date..."
            />
          </Col>
          <Col lg="2" md="2" sm="2" xs="2"></Col>
        </Row>
      </div>
      <div className="category-section">
        <h4>Item Details</h4>

        <div className="table-container">
          <table className="custom-table-ban">
            <thead>
              <tr>
                <th>SNo</th>
                <th>Item</th>
                <th>PO Qty</th>
                <th>PO Unit Price</th>
                <th>PE Qty</th>
                <th>PE Unit Price</th>
                <th>Total</th>
                {/* <th>Actions</th> */}
              </tr>
            </thead>
            <tbody>
              {ItemData?.length > 0
                ? ItemData.map((itm: any, index: any) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="text"
                            name="product"
                            value={itm.product}
                            onChange={(e) => onchange(e, index)}
                            disabled
                          />
                        </td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="number"
                            name="po_qty"
                            value={itm.po_qty}
                            onChange={(e) => onchange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="number"
                            name="po_unit_price"
                            value={itm.po_unit_price}
                            onChange={(e) => onchange(e, index)}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="table-input-style textcenter"
                            name="pe_qty"
                            value={itm.pe_qty}
                            onChange={(e) => onchange(e, index)}
                          />
                          {isValid && itm.pe_qty == "" && (
                            <p
                              style={{
                                margin: "0px 5px",
                                textAlign: "left",
                                color: "red",
                                fontSize: "16px",
                              }}
                            >
                              Required Field.
                            </p>
                          )}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="table-input-style textcenter"
                            name="pe_unit_price"
                            value={itm.pe_unit_price}
                            disabled={itm.pe_unit_price != ""}
                            onChange={(e) => onchange(e, index)}
                          />
                          {isValid && itm.pe_unit_price == "" && (
                            <p
                              style={{
                                margin: "0px 5px",
                                textAlign: "left",
                                color: "red",
                                fontSize: "16px",
                              }}
                            >
                              Required Field.
                            </p>
                          )}
                        </td>
                        <td>
                          <input
                            className="table-input-style textcenter"
                            type="number"
                            name="pe_total"
                            value={itm.pe_total}
                            disabled={itm.pe_total != ""}
                            onChange={(e) => onchange(e, index)}
                          />
                        </td>
                      </tr>
                    );
                  })
                : ""}
              {ItemData?.length > 0 && (
                <tr>
                  <td
                    colSpan={6}
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  ></td>
                  <td className="grandtotal">
                    Grand Total ={" "}
                    {ItemData.reduce(
                      (acc: any, curr: any) =>
                        acc + parseFloat(curr.pe_total || 0),
                      0
                    ).toFixed(2)}
                  </td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="category-section">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginBottom: "1rem",
          }}
        >
          <label
            htmlFor="file-upload"
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              fontSize: "16px",
              color: "#333",
              border: "1px solid #ccc",
              padding: "8px 12px",
              borderRadius: "5px",
              backgroundColor: "#f9f9f9",
            }}
          >
            <FontAwesomeIcon
              icon={faPaperclip}
              style={{ marginRight: "8px" }}
              onClick={handleIconClick}
            />
            <span>Attach Bill</span>
          </label>
          <input
            type="file"
            id="file-upload"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept=".img,.pdf,.png,.jpg,.jpeg"
            onChange={handleFileChange}
          />
          <button
            onClick={handlePreviewClick}
            style={{
              background: "transparent",
              border: "none",
              cursor: "pointer",
              color: "#007bff",
              fontSize: "18px",
              padding: "5px",
              borderRadius: "5px",
              transition: "color 0.3s",
            }}
            onMouseEnter={(e) => (e.currentTarget.style.color = "#0056b3")}
            onMouseLeave={(e) => (e.currentTarget.style.color = "#007bff")}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
        </div>
      </div>

      <div className="category-section">
        <Row>
          <Col lg="3" md="3" sm="3" xs="3">
            <CKEditor
              editor={ClassicEditor}
              data={remarks}
              onChange={(event, editor) => {
                const data = editor.getData();
                setremarks(data);
              }}
              config={{
                placeholder: "Comments or Instruction",
              }}
            />
          </Col>
          <Col lg="3" md="3" sm="3" xs="3" className="flex">
            <Button
              variant="outline-primary subbutton"
              type="submit"
              className="subbtn"
              onClick={submitHandelClick}
            >
              Save{" "}
            </Button>
          </Col>
        </Row>
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit PE Unit Price</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are about to change the PE Unit Price for item{" "}
            {modalContent?.itemIndex + 1}. Old price: {modalContent?.oldPrice}
          </p>
          <Form>
            <Form.Group controlId="formTextInput">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                value={amount}
                onChange={handleTextInputChange}
                placeholder="Enter Amount"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PurchaseEntryform1;
