import React, { FormEvent, useState, ChangeEvent, useEffect } from 'react';
import Papa from "papaparse";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../usermanagement/usermanagement.css";
import { showAlert, showError, showSuccess } from '../../../Utils/sweetAlert';
import { callApi } from '../../../Utils/Api';
import { isEmpty } from 'validator';
import { Table, Pagination, Modal, Button } from "rsuite";
import {
    faAngleLeft,
    faAngleRight,
    faEdit,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import "../../page/site/Sitelist.css";
import { Outlet, useNavigate } from 'react-router-dom';
import useData from 'rsuite/esm/InputPicker/hooks/useData';
import { Form } from 'react-bootstrap';
import ExcelJS from 'exceljs';


const Product = () => {
    const [formData, setFormData] = useState<any>({
        id: '',
        name: '',
        vendor: '',
        price: '',

    });
    const [state, setState] = useState({
        vendor: [
            {
                id: "",
                name: "",
            },
        ]
    })
    const [error, setError] = useState(false);
    const [vendorerror, setvendornoerror] = useState(false);
    const [priceerror, setpriceerror] = useState(false);
    const [productnameerror, setproductnameerror] = useState(false);
    const [passworderror, setpassworderror] = useState(false);
    const [passwordMatch, setPasswordMatch] = useState(false);
    const [confirm_passworderror, setconfirm_passworderror] = useState(false)
    const [modalTitle, setModalTitle] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [showModal, setShowModal] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const { Column, HeaderCell, Cell } = Table;
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);
    const [productlist, setProductlist] = useState<any>([]);
    const navigate = useNavigate()


    const [CustomerVendor, setCustomerVendor] = useState("");
    const totalPages = Math.ceil(productlist.length / rowsPerPage);
    const paginatedData = productlist.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const getData = () => {
        let filteredData = productlist;

        // Apply search filter if searchQuery exists
        if (searchQuery) {
            filteredData = productlist.filter((row: any) =>
                Object.values(row).some((value) =>
                    typeof value === "string"
                        ? value.toLowerCase().includes(searchQuery.toLowerCase())
                        : false
                )
            );
        }

        // Apply sorting if sortColumn and sortType are provided
        if (sortColumn && sortType) {
            filteredData = filteredData.slice().sort((a: any, b: any) => {
                let x = a[sortColumn];
                let y = b[sortColumn];

                if (typeof x === "string" && typeof y === "string") {
                    x = x.toLowerCase();
                    y = y.toLowerCase();
                }

                if (sortType === "asc") {
                    return x > y ? 1 : -1;
                } else {
                    return x < y ? 1 : -1;
                }
            });
        }

        console.log("Filtered Data: ", filteredData);

        // Apply pagination
        const start = (page - 1) * limit;
        const end = start + limit;

        return filteredData.slice(start, end);
    };
    const handleSortColumn = (sortColumn: any, sortType: any) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
        setPage(1);
        setLimit(dataKey);
    };

    const data = productlist.filter((v: any, i: any) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    });
    const handleCloseModal = () => {
        setShowModal(false);
        setFormData({
            id: "",
            name: "",
            // price: "",
            // vendor: "",

        });
        setError(false)
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };


    const handleCheckbox = async (e: any, id: any) => {
        const isComfirm = await showAlert(
            "Please Confirm",
            "Are you sure you want to change the status?",
            "Yes",
            "No"
        );
        const { checked } = e.target;
        console.log("handleCheckbox_____", checked);
        if (isComfirm) {
            const req = {
                id: id,
                status: checked == false ? "1" : "0"
            }
            callApi("POSt", "product_delete.php", req)
                .then((res: any) => res.data)
                .then((response: any) => {
                    if (response.responseCode === 200) {
                        showSuccess("CHANGE!", response.message);
                        productDetails();
                    } else {
                        showError("FAILED!", response.message);
                    }
                });
        }
    }
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData((prevState: any) => ({
            ...prevState,
            [name]: value
        }));
        if (name === "price") {
            const isValidInput = /^[0-9/]*$/.test(value);
            if (!isValidInput) {
                setpriceerror(true);
            } else {
                setpriceerror(false);
            }
        } else if (name === "name") {
            const isValidInput = /^[a-zA-Z0-9\W]*$/.test(value);
            if (!isValidInput) {
                setproductnameerror(true);
                console.error("Invalid input. Please enter only characters.");
            } else {
                setproductnameerror(false);
            }
        }

    };
    useEffect(() => {
        productDetails();
        GetVendorDetails();
    }, []);

    const handleproductcreate = (e: FormEvent) => {
        e.preventDefault();
        console.log("vendorselect----->", CustomerVendor)
        console.log(
            "formFields_________",
            formData
        );


        if (
            isEmpty(formData.name)
            // isEmpty(formData.price) ||
            // isEmpty(CustomerVendor)

        ) {
            setError(true)
        } else {
            console.log("jgjhjh", modalTitle);


            if (modalTitle === "Add Product") {
                let request = {
                
                    "product_name": formData.name,
                    // "vendor_id": CustomerVendor,
                    // "product_price": formData.price

                };
                console.log(request);

                callApi("POST", "product_create.php", request)
                    .then((res: any) => res.data)
                    .then((response: any) => {
                        if (response.responseCode === 200) {
                            showSuccess("Success!", response.message);
                            setFormData({
                                name: "",
                                // price: "",
                                // vendor: "",

                            });
                            setShowModal(false);
                            productDetails();
                        }
                        else {
                            showError("Failed", response.message);
                        }
                    });
            }
            else {

                editingdetails();
            }
        }
    };
    const GetVendorDetails = () => {
        callApi("POST", "vendor_list.php", "")
            .then((res) => res.data)
            .then((response) => {
                console.log("response--->111", response.responseJson);

                setState((prevState: any) => ({
                    ...prevState,
                    vendor: response.responseJson,
                }));
            })
            .catch((err) => {
                console.log(err);
            });

        callApi("POST", "product_list.php", "")
            .then((res) => res.data)
            .then((response) => {
                console.log("response--->222", response.responseJson);

                setState((prevState: any) => ({
                    ...prevState,
                    item: response.responseJson,
                }));
            })
            .catch((err) => {
                console.log(err);
            });

        let req = {
            "site_id": "1"
        }

        callApi("POST", "vendor_list.php", req)
            .then((res) => res.data)
            .then((response) => {
                if (response["response_Code"] == 200) {
                    setState((prevState: any) => ({
                        ...prevState,
                        poData: response?.responseJson[0],
                    }));

                    setCustomerVendor(response?.responseJson[0]?.vendor_id);
                }
            })
            .catch((err) => {
                console.log(err);
            });
        console.log("poData_____________", state);
    };



    const productDetails = () => {
        callApi("post", "product_list.php", "")
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response["response_Code"] === 200) {
                    console.log("product--->", response.responseJson)
                    setProductlist(response.responseJson)

                }
                console.log("product--->", productlist)
            })
    }

    const addProduct = () => {
        setModalTitle("Add Product");
        setShowModal(true)
    };

    useEffect(() => {
        console.log("formData", formData);
    }, [formData]);

    const productEdit = (data: any) => {
        console.log("this is the data", data)
        setFormData({
            id: data.id,
            name: data.product_name,
            // price: data.product_price,
            // vendor: data.vendor,

        });
        setModalTitle("Edit Product");
        setShowModal(true);
    };

    const editingdetails = () => {
       
        
        let request = {
            "id": formData.id,
            "product_name": formData.name,
            // "vendor_id":formData.vendor,
            // "product_price": formData.price
        };
        console.log("req", request)
        callApi("post", "product_update.php", request)
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response.responseCode === 200) {
                    showSuccess("EDITED!", response.message);
                    productDetails();
                    setShowModal(false);
                    setFormData({

                        id: "",
                        name: ""

                    })
                } else {
                    showError("FAILED!", response.message);
                }
            });

    };

    // const userDelete = async (row: any) => {
    //     const isComfirm = await showAlert(
    //         "Please Confirm",
    //         "Are you sure you want to delete the user?",
    //         "Yes",
    //         "No"
    //     );
    //     if (isComfirm) {
    //         let requ = {
    //             "user_id": row.id,
    //         };
    //         callApi("POSt", "user_delete.php", requ)
    //             .then((res: any) => res.data)
    //             .then((response: any) => {
    //                 if (response.responseCode === 200) {
    //                     showSuccess("DELETED!", response.message);
    //                     productDetails();
    //                 } else {
    //                     showError("FAILED!", "Delete failed");
    //                 }
    //             });
    //     }
    // };

    const downloadCSV = () => {
        const data = [
            ["S.No", "Product"],
            ...productlist.map((row: any) => [row.s_no, row.product_name])
        ];
    
        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');
    
        // Add rows to the worksheet
        data.forEach((row) => {
            worksheet.addRow(row);
        });
    
        // Merge the title row across all columns and center the title
        worksheet.mergeCells('A1:B1');
        const titleCell = worksheet.getCell('A1');
        titleCell.value = "Product List"; // Set the title value
        titleCell.alignment = { horizontal: 'center', vertical: 'middle' };
        titleCell.font = { bold: true, size: 14 };
    
        // Style for the header row (second row)
        const headerRow = worksheet.getRow(2);
        headerRow.eachCell((cell: any) => {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
            cell.font = { bold: true };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFF' } }; // Yellow background
        });
    
        // Write the workbook to an Excel file
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        workbook.xlsx.writeBuffer().then((buffer: any) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `Productdetails_${formattedDate}.xlsx`;
            link.click();
        });
    };
    const handleproductClick = (rowData: any) => {
        console.log("rowData", rowData.id);
        navigate('/layout/nameofproduct', { state: { rowData } }); // Navigate to the vendor page within layout
    };
    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default form submission
            // You can add any additional actions here if needed
        }
    };

    return (
        <><>
            <div className="container-qt">
                {/* <div className="rs-panel-body"> */}
                    <div className="rs-table rs-table-hover">
                    <div className="tabletitle" style={{ fontSize: "24px" }}>Product Details</div>
                        <div className="table-header ">
                        <div className='vendor-add-btn'>
                            <button
                                type="button"
                                className="btn excelbtn exbtn"
                                style={{ marginRight: "10px" }}
                                onClick={addProduct}
                            >
                                Add Product
                            </button>
                            </div>
                            <div className="table-search searchfieldscontain vendor-excel">
                            <button
                                type="button"
                                className="btn excelbtn btnmobiledash"
                                style={{ marginRight: "10px" }}
                                onClick={downloadCSV}
                            >
                                Excel
                            </button>
                                <div className="search-field1">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="search-input vendorsearch"
                                        value={searchQuery}
                                        onChange={handleSearchChange} />
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", overflowX: "hidden" }}>
                            <Table
                                height={420}
                                data={getData()}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSortColumn={handleSortColumn}
                                loading={loading}
                                style={{ width: "100%" }} // Adjust min-width as needed
                            >
                                <Column width={50} align="center" fixed>
                                    <HeaderCell>S.No</HeaderCell>
                                    <Cell>
                                    {(rowData) => (
                                            <p onClick={() => handleproductClick(rowData)}>{rowData.s_no}</p>
                                        )}
                                         </Cell>
                                </Column>

                                <Column width={120}>
                                    <HeaderCell>Product</HeaderCell>
                                    <Cell>
                                        {(rowData) => (
                                            <p onClick={() => handleproductClick(rowData)}>{rowData.product_name}</p>
                                        )}
                                    </Cell>
                                </Column>
                                {/* <Column width={100}>
                                    <HeaderCell>Vendor</HeaderCell>
                                    <Cell dataKey="vendor_name">

                                    </Cell>
                                </Column>
                                <Column width={100}>
                                    <HeaderCell>Price</HeaderCell>
                                    <Cell dataKey="product_price">

                                    </Cell>
                                </Column> */}

                                <Column width={100}>
                                    <HeaderCell>Action</HeaderCell>
                                    <Cell>
                                        {(rowData) => (
                                            <div>
                                                <button onClick={() => productEdit(rowData)} className='iconbg'><FontAwesomeIcon icon={faEdit} style={{ height: "20px", width: "20px" }} /></button>
                                            </div>
                                        )}
                                    </Cell>
                                </Column>
                                <Column width={100}>
                                    <HeaderCell>Status</HeaderCell>
                                    <Cell>
                                        {(rowData) => (
                                            <label className="switch">
                                                <input type="checkbox" checked={rowData.status ==
                                                    '1'
                                                } onChange={(e) => handleCheckbox(e, rowData.id)} />
                                                <span className="slider round"></span>
                                            </label>
                                        )}
                                    </Cell>
                                </Column>
                            </Table>
                        </div>
                        <div>
                            <Pagination
                                prev
                                next
                                first
                                last
                                ellipsis
                                boundaryLinks
                                maxButtons={5}
                                className="pagination"
                                size="xs"
                                layout={["total", "pager", "skip"]}
                                total={productlist.length}
                                limitOptions={[5, 10, 15]}
                                limit={limit}
                                activePage={page}
                                onChangePage={setPage}
                                onChangeLimit={handleChangeLimit}
                            />
                        </div>
                    </div>
                {/* </div> */}


            </div>

            {/* Modal for adding new user */}
            <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title className='modaltittle'>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{ padding: "2%", overflow: "hidden" }}>
                        <div className='row'>
                            <div className="col-md-12 form-group">
                                <label htmlFor="name" className='labeltittle'>Product Name<span className='mandatoryfield'>*</span></label>
                                <input
                                    type="text"
                                    className={`form-control ${error && isEmpty(formData.name) ? 'is-invalid' : ''} mt-1 mb-2`}
                                    id="name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    onKeyDown={handleKeyDown}
                                    minLength={2}
                                    maxLength={50} />
                                {error && isEmpty(formData.name) && <div className="invalid-feedback">{"Product Name Error"}</div>}
                                {productnameerror && <div className="error-message" style={{ color: "red" }}>Enter valid Name</div>}
                            </div>
                            {/* <div className="col-md-6 form-group">
                        <label className='labeltittle'>Vendor</label>
                        <Form.Select
                            className='form-control'
                            aria-label=""
                            style={{ height: "58%" }}
                            value={CustomerVendor || ""}
                            onChange={(e) => setCustomerVendor(e.target.value)}
                        > 
                            {state.vendor.map((vendor) => (
                                <option
                                    key={vendor.id}
                                    value={vendor.id}
                                >
                                    {vendor.name}
                                </option>
                            ))}
                        </Form.Select>
                            {error && isEmpty(formData.vendor) && <div className="invalid-feedback">{"vendor Error"}</div>}
                            {vendorerror && <div className="error-message" style={{ color: "red" }}>Enter valid vendor</div>}
                        </div> */}
                        </div>
                        {/* <div className='row'>
                        <div className="col-md-6 form-group">
                            <label htmlFor="price" className='labeltittle'>Price</label>
                            <input
                                type="price"
                                className={`form-control ${error && isEmpty(formData.price) ? 'is-invalid' : ''} mt-1 mb-2`}
                                id="price"
                                name="price"
                                value={formData.price}
                                onChange={handleChange} />
                            {error && isEmpty(formData.price) && <div className="invalid-feedback">{"Price Error"}</div>}
                            {priceerror && <div className="error-message" style={{ color: "red" }}>Enter valid Price Amount</div>}
                        </div>
                        </div> */}
                        <Button className='newuser mt-3' onClick={(e) => handleproductcreate(e)}>{modalTitle}</Button>
                    </form>
                </Modal.Body>
            </Modal>
        </><Outlet /></>
    );
};

export default Product;

