import React, { FormEvent, useState, ChangeEvent, useEffect } from "react";
import Papa from "papaparse";
import { useNavigate, useParams } from "react-router-dom";

// import { Table, Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowLeft, faArrowRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import "../../layout/usermanagement/usermanagement.css";
import { showAlert, showError, showSuccess } from "../../../Utils/sweetAlert";
import { callApi } from "../../../Utils/Api";
import { isEmpty } from "validator";
import { title } from "process";
import { log } from "console";
// import { Pagination } from 'rsuite';
import Column from "rsuite/esm/Table/TableColumn";
import { Button, Modal, Pagination, Table } from "rsuite";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

const Completedsite = () => {
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    mobile: "",
    email: "",
    password: "",
    confirm_password: "",
  });
  const { Column, HeaderCell, Cell } = Table;
  const [error, setError] = useState(false);
  const [mobilenoerror, setMobilenoerror] = useState(false);
  const [emailerror, setEmailerror] = useState(false);
  const [nameerror, setNameerror] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [confirm_passworderror, setconfirm_passworderror] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [state, setState] = useState({
    UserListSet: [],
  });
  // const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  const [dataPeriod, setDataPeriod] = useState<"yearly" | "monthly">("monthly");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showModal, setShowModal] = useState(false);
  const [limit, setLimit] = React.useState(5);
  const [page, setPage] = React.useState(1);
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [tableData, setTableData] = useState<any[]>([]);

  const totalPages = Math.ceil(tableData.length / rowsPerPage);
  const paginatedData = tableData.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSortColumn = (sortColumn: any, sortType: any) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 500);
  };
  const handleChangeLimit = (dataKey: React.SetStateAction<number>) => {
    setPage(1);
    setLimit(dataKey);
  };
  const filteredData = paginatedData.filter(
    (row: any) =>
      (row.site_name &&
        row.site_name.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.invoice_value &&
        row.invoice_value.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (row.balance_payment &&
        row.balance_payment
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (row.available_for_po &&
        row.available_for_po.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const data = tableData.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });
  const getData = () => {
    let filteredData = tableData;

    // Apply search filter if searchQuery exists
    if (searchQuery) {
      filteredData = tableData.filter((row) =>
        Object.values(row).some((value) =>
          typeof value === "string"
            ? value.toLowerCase().includes(searchQuery.toLowerCase())
            : false
        )
      );
    }

    // Apply sorting if sortColumn and sortType are provided
    if (sortColumn && sortType) {
      filteredData = filteredData.slice().sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];

        if (typeof x === "string" && typeof y === "string") {
          x = x.toLowerCase();
          y = y.toLowerCase();
        }

        if (sortType === "asc") {
          return x > y ? 1 : -1;
        } else {
          return x < y ? 1 : -1;
        }
      });
    }

    console.log("Filtered Data: ", filteredData);

    // Apply pagination
    const start = (page - 1) * limit;
    const end = start + limit;

    return filteredData.slice(start, end);
  };

  const downloadCSV = () => {
    const data = [
      [
        "S.No",
        "Site Name",
        "Invoice Number",
        "Invoice Value",
        "Expense Value",
        "P/L",
      ],
      ...tableData.map((row) => [
        row.id,
        row.siteName,
        row.invoice_number,
        row.invoiceValue,
        row.expense_value,
        row.profit_lose,
      ]),
    ];

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "Completed-Site.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({
      id: "",
      name: "",
      email: "",
      mobile: "",
      password: "",
      confirm_password: "",
    });
    setError(false);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Update state for form fields
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Perform validation based on field name
    if (name === "mobile") {
      const isValidInput = /^[0-9/-]*$/.test(value);
      if (!isValidInput || value.length < 10) {
        setMobilenoerror(true);
        console.error(
          "Invalid input. Please enter only numeric characters, slash, or hyphen."
        );
      } else {
        setMobilenoerror(false);
      }
    } else if (name === "email") {
      const isValidInput =
        /^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value);
      const endsWithValidDomain =
        value.endsWith(".com") || value.toLowerCase().endsWith(".in");
      if (!isValidInput || !endsWithValidDomain) {
        setEmailerror(true);
      } else {
        setEmailerror(false);
      }
    } else if (name === "name") {
      const isValidInput = /^[a-zA-Z ]+$/.test(value);
      if (!isValidInput) {
        setNameerror(true);
        console.error("Invalid input. Please enter only characters.");
      } else {
        setNameerror(false);
      }
    } else if (name === "password") {
      const isValidInput =
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(value);
      if (!isValidInput) {
        setpassworderror(true);
        console.error("Invalid input. Please enter a valid password.");
      } else {
        setpassworderror(false);
      }
      if (formData.confirm_password !== value) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    } else if (name === "confirm_password") {
      const isValidInput =
        /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(value);
      if (!isValidInput) {
        setpassworderror(true);
        console.error("Invalid input. Please enter a valid password.");
      } else {
        setpassworderror(false);
      }
      if (formData.password !== value) {
        setPasswordMatch(true);
      } else {
        setPasswordMatch(false);
      }
    }
  };
  useEffect(() => {
    getSitelist();
  }, []);

  const handlesaveuser = (e: FormEvent) => {
    e.preventDefault();

    console.log("formFields_________", formData);

    if (
      isEmpty(formData.name) ||
      isEmpty(formData.email) ||
      isEmpty(formData.mobile) ||
      isEmpty(formData.password) ||
      isEmpty(formData.confirm_password)
      // isEmpty(formData.mobile.length < 10)
    ) {
      setError(true);
    } else if (
      nameerror ||
      emailerror ||
      mobilenoerror ||
      passworderror ||
      confirm_passworderror ||
      passwordMatch
    ) {
      // if (nameerror === true) {
      //     showError("Name Error", "Form not Valid!");
      // } else if (emailerror === true) {
      //     showError("Email Error", "Form not Valid!");
      // } else if (mobilenoerror === true) {
      //     showError("Mobile number Error", "Form not Valid!");
      // } else if (passworderror === true) {
      //     showError("Password Error", "Form not Valid!");
      // } else if (confirm_passworderror === true) {
      //     showError("Conformpassword Error", "Form not Valid!");
      // } else {
      //     showError("Password does not match Error", "Form not Valid!");
      // }
    } else {
      console.log("jgjhjh", modalTitle);

      if (modalTitle === "Add User") {
        let request = {
          name: formData.name,
          email: formData.email,
          password: formData.password,
          mobile_no: formData.mobile,
          user_type: 2,
        };
        callApi("POST", "user_create.php", request)
          .then((res: any) => res.data)
          .then((response: any) => {
            if (response.responseCode === 200) {
              showSuccess("Success!", response.message);
              setFormData({
                id: "",
                name: "",
                email: "",
                mobile: "",
                password: "",
                confirm_password: "",
              });
              setShowModal(false);
              getSitelist();
            } else {
              showError("Failed", response.message);
            }
          });
      } else {
        editingdetails();
      }
    }
  };
  const getSitelist = () => {
    callApi("post", "site_list.php", "")
      .then((res: any) => {
        if (res.data && res.data["response_Code"] === 200) {
          const data: any = [];
          res.data.responseJson.forEach((element: any) => {
            if (element.completed_site_status === "1") {
              data.push(element);
            }
          });
          console.log("site_list_________________1", data);
          setTableData(data);
        } else {
          console.error("Failed to fetch site list");
        }
      })
      .catch((error) => {
        console.error("Error fetching site list:", error);
      });
  };

  const addUser = () => {
    setModalTitle("Add User");
    setShowModal(true);
  };

  const userEdit = (data: any) => {
    console.log("this is the data", data);
    setFormData({
      id: data.id,
      name: data.name,
      email: data.email,
      mobile: data.mobile_no,
      password: data.password,
      confirm_password: data.confirm_password,
    });
    setModalTitle("Edit User");
    setShowModal(true);
  };

  const editingdetails = () => {
    let request = {
      user_id: formData.id,
      name: formData.name,
      email: formData.email,
      password: formData.password,
      mobile_no: formData.mobile,
    };
    callApi("post", "user_update.php", request)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response.responseCode === 200) {
          showSuccess("EDITED!", response.message);
          getSitelist();
          setFormData({
            id: "",
            name: "",
            email: "",
            mobile: "",
            password: "",
            confirm_password: "",
          });
          setShowModal(false);
        } else {
          showError("FAILED!", response.message);
        }
      });
  };

  const userDelete = async (row: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to delete the user?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      let requ = {
        user_id: row.id,
      };
      callApi("POSt", "user_delete.php", requ)
        .then((res: any) => res.data)
        .then((response: any) => {
          if (response.responseCode === 200) {
            showSuccess("DELETED!", response.message);
            getSitelist();
          } else {
            showError("FAILED!", response.message);
          }
        });
    }
  };

  const renderProfitLoseCell: any = (rowData: any, rowIndex: any) => {
    const profitLose = rowData.profit_lose;

    const cellStyle: any = {
      backgroundColor:
        profitLose > 0 ? "#93c593" : profitLose < 0 ? "#f5a2a2" : "white",
      // textAlign: 'center',
      width: "100px !important",
    };

    return <p style={cellStyle}>{profitLose}</p>;
  };
  const handlegoBack = () => {
    navigate(-1);
  };
  const handleSiteClick = (rowData: any) => {
    navigate(`/layout/sitedetails/${rowData.id}`);
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        {" "}
        <button className="btn excelbtn mr-2" onClick={handlegoBack}>
          Go Back
        </button>
      </div>
      <div className="container-qt">
        <div className="rs-panel-body">
          <div className="rs-table rs-table-hover">
            <div className="table-header ">
              <button
                type="button"
                className="btn excelbtn"
                style={{ marginRight: "10px" }}
                onClick={downloadCSV}
              >
                Excel
              </button>

              <div className="table-search">
                <div className="search-field1">
                  <input
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
            <div style={{ width: "100%", overflowX: "hidden" }}>
              <Table
                height={300}
                data={getData()}
                sortColumn={sortColumn}
                sortType={sortType}
                onSortColumn={handleSortColumn}
                loading={loading}
                style={{ width: "100%", overflow: "hidden" }} // Adjust min-width as needed
              >
                <Column width={100} align="center" fixed>
                  <HeaderCell>S.No</HeaderCell>
                  <Cell>
                    {(rowData, rowIndex) =>
                      rowIndex !== undefined ? rowIndex + 1 : ""
                    }
                  </Cell>
                </Column>

                <Column width={200} align="cen1er">
                  <HeaderCell>Name</HeaderCell>
                  <Cell>
                    {(rowData) => (
                      <div
                        onClick={() => handleSiteClick(rowData)}
                        className="underline-qt"
                      >
                        {rowData.name}
                      </div>
                    )}
                  </Cell>
                </Column>
                <Column width={150} align="center">
                  <HeaderCell>Invoice Number</HeaderCell>
                  <Cell dataKey="invoice_number"></Cell>
                </Column>
                <Column width={150} align="center">
                  <HeaderCell>Invoice Value</HeaderCell>
                  <Cell dataKey="invoice_value"></Cell>
                </Column>
                <Column width={150} align="center">
                  <HeaderCell>Expense Value</HeaderCell>
                  <Cell dataKey="expense_value"></Cell>
                </Column>
                {/* <Column width={100} align="center">
                                    <HeaderCell >P/L</HeaderCell>
                                    <Cell dataKey="profit_lose" render={renderProfitLoseCell}>

                                    </Cell>
                                </Column> */}

                <Column width={200} align="center">
                  <HeaderCell>P/L</HeaderCell>
                  <Cell dataKey="profit_lose">{renderProfitLoseCell}</Cell>
                </Column>

                {/* <Column width={100} align="center">
                                    <HeaderCell>Action</HeaderCell>
                                    <Cell >
                                        {(rowData) => (
                                            <div>
                                                <button onClick={() => userEdit(rowData)}><FontAwesomeIcon icon={faEdit} style={{ height: "20px", width: "20px" }} /></button>
                                                <button onClick={() => userDelete(rowData)}><FontAwesomeIcon icon={faTrash} style={{ height: "20px", width: "20px" }} /></button>
                                            </div>
                                        )}
                                    </Cell>
                                </Column> */}
              </Table>
            </div>
            <div style={{ padding: 20 }}>
              <Pagination
                prev
                next
                first
                last
                ellipsis
                boundaryLinks
                maxButtons={5}
                className="pagination"
                size="xs"
                layout={["total", "pager", "skip"]}
                total={tableData.length}
                limitOptions={[5, 10, 15]}
                limit={limit}
                activePage={page}
                onChangePage={setPage}
                onChangeLimit={handleChangeLimit}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal open={showModal} onClose={handleCloseModal} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title className="modaltittle">User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form style={{ padding: "2%", overflow: "hidden" }}>
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="name" className="labeltittle">
                  Name
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    error && isEmpty(formData.name) ? "is-invalid" : ""
                  } mt-1 mb-2`}
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {error && isEmpty(formData.name) && (
                  <div className="invalid-feedback">{"Name Error"}</div>
                )}
                {nameerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid Name
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="mobile" className="labeltittle">
                  Mobile
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    error && isEmpty(formData.mobile) ? "is-invalid" : ""
                  } mt-1 mb-2`}
                  id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  maxLength={10}
                />
                {error && isEmpty(formData.mobile) && (
                  <div className="invalid-feedback">
                    {"Mobile number Error"}
                  </div>
                )}
                {mobilenoerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid Mobile Number
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="email" className="labeltittle">
                  Email
                </label>
                <input
                  type="email"
                  className={`form-control ${
                    error && isEmpty(formData.email) ? "is-invalid" : ""
                  } mt-1 mb-2`}
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {error && isEmpty(formData.email) && (
                  <div className="invalid-feedback">{"Email Error"}</div>
                )}
                {emailerror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Enter valid Email
                  </div>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 form-group">
                <label htmlFor="password" className="labeltittle">
                  Password
                </label>
                <input
                  type="password"
                  className={`form-control ${
                    error && isEmpty(formData.password) ? "is-invalid" : ""
                  } mt-1 mb-2`}
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
                {error && isEmpty(formData.password) && (
                  <div className="invalid-feedback">{"Password Error"}</div>
                )}
                {passworderror && (
                  <div className="error-message" style={{ color: "red" }}>
                    Password must contain at least 8 characters, one digit, and
                    one special character
                  </div>
                )}
              </div>
              <div className="col-md-6 form-group">
                <label htmlFor="confirm_password" className="labeltittle">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className={`form-control ${
                    error && isEmpty(formData.confirm_password)
                      ? "is-invalid"
                      : ""
                  } mt-1 mb-2`}
                  id="confirm_password"
                  name="confirm_password"
                  value={formData.confirm_password}
                  onChange={handleChange}
                />
                {error && isEmpty(formData.confirm_password) && (
                  <div className="invalid-feedback">{""}</div>
                )}
                {passwordMatch && (
                  <div className="error-message" style={{ color: "red" }}>
                    Passwords do not match
                  </div>
                )}
              </div>
            </div>
            <Button className="newuser mt-3" onClick={(e) => handlesaveuser(e)}>
              Save User
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Completedsite;
